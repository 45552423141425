/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CRow,
} from '@coreui/react';

import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import BgvAuthorizationConsent from './other/bgv_authorization_consent';
import { toast, ToastContainer } from 'react-toastify';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';

const PolicyDetails = props => {
  const api = new API();
  const { getEmployeeDetailStatus, empStatus, empDetails } = props;
  const [accordion, setAccordion] = useState(0);
  const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);
  const [policy3, setPolicy3] = useState(false);
  const [policy4, setPolicy4] = useState(false);
  const [policy5, setPolicy5] = useState(false);
  const [policy6, setPolicy6] = useState(false);
  const [policy7, setPolicy7] = useState(false);
  const [policy8, setPolicy8] = useState(false);
  const [policy9, setPolicy9] = useState(false);
  const [policy10, setPolicy10] = useState(false);
  const [policy11, setPolicy11] = useState(false);
  const [policy12, setPolicy12] = useState(false);
  const [policy13, setPolicy13] = useState(false);
  const [policy14, setPolicy14] = useState(false);
  const [policy15, setPolicy15] = useState(false);
  const [policy16, setPolicy16] = useState(false);
  const [policy17, setPolicy17] = useState(false);
  const [policy18, setPolicy18] = useState(false);
  const [policy19, setPolicy19] = useState(false);
  const [allPolicyAccept, setAllPolicyAccept] = useState(true);
  const [userSign, setUserSign] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [policyData, setPolicyData] = useState('');
  const [userID, setUserID] = useState('');
  const [joiningDate, setJoiningDate] = useState('');

  useEffect(() => {
    let userData = getLocalDataAsObject('user');
    if (userData && userData.length > 0) {
      setUserEmail(userData[0].email);
      setUserID(userData[0].id);
    }
  }, []);
  const Policyaccepted = async () => {
    if (!userSign) {
      toast.warning('Please sign');
      return;
    }
    let data = {};
    if (empDetails && empDetails.id) {
      data = {
        empPolicyAccept: allPolicyAccept,
        user_id: empDetails && empDetails.id,
        sign: userSign,
      };
    } else {
      data = {
        empPolicyAccept: allPolicyAccept,
        sign: userSign,
      };
    }

    let result = await api.post(config.employeePolicy, data);
    if (result && result.code === 200) {
      toast.success('Policy data submited successfully')
      // alert('Policy data submited successfully');
      getEmployeeDetailStatus();
    } else {
      toast.error(result && result.message)

    }
  };

  const dataForPolicy = async () => {
    let data = {
      emp_user_id: empDetails?.id ? empDetails?.id : userID,
    };
    let result = await api.get(config.allDataforPolicy, true, data);
    if (result && result.code === 200) {
      setPolicyData(result.data[0]);
      setJoiningDate(result?.data?.[0]?.joining_date || moment().format('YYYY-MM-DD'));
    } else {
      toast.error(result && result.message)
    }
  };
  useEffect(() => {
    dataForPolicy();
  }, [userID, empDetails]);

  const acceptpolicy = (e, id) => {
    switch (id) {
      case 1:
        setPolicy1(!policy1);
        break;
      case 2:
        setPolicy2(!policy2);
        break;
      case 3:
        setPolicy3(!policy3);
        break;
      case 4:
        setPolicy4(!policy4);
        break;
      case 5:
        setPolicy5(!policy5);
        break;
      case 6:
        setPolicy6(!policy6);
        break;
      case 7:
        setPolicy7(!policy7);
        break;
      case 8:
        setPolicy8(!policy8);
        break;
      case 9:
        setPolicy9(!policy9);
        break;
      case 10:
        setPolicy10(!policy10);
        break;
      case 11:
        setPolicy11(!policy11);
        break;
      case 12:
        setPolicy12(!policy12);
        break;
      case 13:
        setPolicy13(!policy13);
        break;
      case 14:
        setPolicy14(!policy14);
        break;
      case 15:
        setPolicy15(!policy15);
        break;
      case 16:
        setPolicy16(!policy16);
        break;
      case 17:
        setPolicy17(!policy17);
        break;
      case 18:
        setPolicy18(!policy18);
        break;
      case 19:
        setPolicy19(!policy19);
        break;
      default:
      // console.log("something went wrong")
    }
    // isAllPolicyAccept()
  };

  const getSignFun = sign => {
    setUserSign(sign);
  };
  const sendOtp = async () => {
    let data = {
      email: userEmail,
      type: 'other',
      subject: 'OTP Verification for Policy',
    };
    let result = await api.post(config.sendOtp, data);
    // console.log("Result>>>>>>>>>", result)
    if (result && result.code === 200) {
      toast.success(result && result.message)
      setIsOtpSent(true);
    } else {
      toast.error(result && result.message)
    }
  };
  const verifyOTP = async () => {
    let data = {
      email: userEmail,
      otp: otp,
    };
    let result = await api.post(config.verifyOtp, data);
    if (result && result.code === 200) {
      toast.success(result && result.message)
      setIsVerified(true);
    } else {
      toast.error(result && result.message)
    }
  };

  return (
    <CRow>
      <CCol xl="12">
        {true && (
          <CCard>
            <div className="text-center">
              <strong>COMPANY POLICY</strong>
              <br />
              <small>
                {' '}
                Kindly read all the policies by clicking respective one's
              </small>
            </div>

            <CCardBody>
              <div id="accordion">
                <CCard className="mb-0">
                  <CCardHeader id="headingOne">
                    <CButton
                      block
                      color=""
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 0 ? null : 0)}
                    >
                      <h5 className="m-0 p-0 text-black">
                        Purpose of Company Policy
                        <i
                          className={
                            accordion === 0
                              ? 'fa fa-angle-double-up fa-lg text-black'
                              : 'fa fa-angle-double-down fa-lg text-black'
                          }
                          style={{ float: 'right' }}
                        ></i>
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 0}>
                    <CCardBody>
                      The purpose of this policy is to administer and clarify to all employees and staff members of the
                      Company’s general policy in terms of its objectives, operations, and future plans while laying
                      the foundation for employees and staff members in terms of conduct and expected
                      performance to achieve said objectives, operations, and future plans.

                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 1)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 1 ? null : 1)}
                    >
                      <h5 className="m-0 p-0 ">
                        Scope
                        <i
                          className={
                            accordion === 1
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 1}>
                    <CCardBody>
                      This policy will be utilised and applied to all workers and staff members of CBT, regardless of
                      their department and the position they hold.

                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 2)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingThree">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 2 ? null : 2)}
                    >
                      <h5 className="m-0 p-0 ">
                        Policy Statement
                        <i
                          className={
                            accordion === 2
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 2}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Duration of agreement</strong>
                      <ul>
                        This agreement shall continue to be valid and effective from 15th Nov , 2024 till the same is
                        separated.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 3)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingFour">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 3 ? null : 3)}
                    >
                      <h5 className="m-0 p-0 ">
                        Code of Conduct
                        <i
                          className={
                            accordion === 3
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 3}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Collaboration</strong>
                      <ul>
                        Employees should strive to be friendly and collaborative. They should not try to disrupt the
                        workplace or present obstacles in their colleagues’ work.

                      </ul>
                      <strong style={{ color: "black" }}>Communication</strong>
                      <ul>
                        All employees must be open for clear communication with their colleagues, supervisors or team
                        members.Emphasizing the importance of respectful and inclusive communication, including
                        guidelines for avoiding discriminatory language, harassment, or offensive behaviour.

                      </ul>  <strong style={{ color: "black" }}>Benefits</strong>
                      <ul>
                        We expect our employees not to misuse their employment benefits which entails time off,
                        subscriptions, facilities, or other benefits our company offers.


                      </ul>  <strong style={{ color: "black" }}>Protection of company property</strong>
                      <ul>
                        All employees of our company should treat our company’s property, whether material or
                        intangible with respect and care.

                      </ul>  <strong style={{ color: "black" }}>Employees should :</strong>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          Refrain from misuse of company equipment or using it frivolously.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Respect all kinds of incorporeal property. This includes trademarks, copyright and other
                          property (information, reports etc.). Employees should only use them to perform their
                          job duties.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Strive to protect company facilities and other material property from damage and
                          vandalism, whenever possible.

                        </li>

                      </ul>  <strong style={{ color: "black" }}>Conflict of interest</strong>
                      <ul>
                        We expect employees to avoid any personal, financial or other interests that might hinder their
                        capacity or willingness to perform their job duties.

                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 4)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 4 ? null : 4)}
                    >
                      <h5 className="m-0 p-0 ">
                        Leave Policy
                        <i
                          className={
                            accordion === 4
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 4}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Privileged Leave :</strong>
                      <ul>
                        Employees are entitled to 10 privileged leave per year. Privileged leave
                        requires advance approval from the Reporting Manager or Business Head via email. Employees
                        are allowed to take a maximum of 7 privileged leaves together in one instance. Any additional
                        leave beyond 7 consecutive days will require approval and may be considered unpaid.
                        <li style={{ listStyleType: 'disc' }}>
                          <b>Sunday in Privileged Leave:</b> If a Sunday falls within the period of privileged leave, it will
                          be counted as part of the privileged leave days.
                        </li>
                      </ul>
                      <strong style={{ color: "black" }}>Sick Leave :</strong>
                      <ul>
                        Employees are entitled to 6 sick leave days per year. Any sick leave taken requires
                        submission of a medical certificate.

                        <li style={{ listStyleType: 'disc' }}>
                          You cannot take sick and casual leave consecutively. If two sick leaves are taken
                          together, you are ineligible for a casual leave immediately following the sick leave.

                        </li>
                      </ul>
                      <strong style={{ color: "black" }}>Casual Leave :</strong>
                      <ul>
                        Employees are granted 8 casual leave days per year. Employees are allowed to
                        take a maximum of 2 casual leaves together in one instance. Any additional leave beyond 2
                        consecutive days will require approval and may be considered unpaid.
                      </ul>
                      <strong style={{ color: "black" }}>Holiday List :</strong>
                      <ul>
                        Only holidays from the official company list (provided separately) are applicable.
                      </ul>
                      <strong style={{ color: "black" }}>Probation Period Leave :</strong>
                      <ul>
                        No privileged leaves and casual leaves are available during their
                        probation period for all new joinees.
                      </ul>
                      <strong style={{ color: "black" }}>Sandwich Leave Policy :</strong>
                      <ul>
                        Opting for leave on Saturday renders Monday off ineligible. Pay for
                        Saturday, Sunday, and Monday will be deducted if Monday leave is taken. Additionally, the
                        casual leave chosen for Saturday will not apply if Monday off is taken.
                      </ul>
                      <strong style={{ color: "black" }}>Leave Approval Process :</strong>
                      <ul>
                        Every leave request must be sent via email to the Reporting Manager
                        or Business Head, with HR (hr@cbt.ind.in) in CC. Only leaves approved via this email protocol
                        will be counted as valid.

                      </ul>
                      <strong style={{ color: "black" }}>Leave Carry Forward Policy :</strong>
                      <ul>
                        As per the applicable labor laws, casual leaves may not be carried
                        forward to the next year and must be utilised within the calendar year. Unused privileged leave
                        can usually be carried forward or encashed based on company policy.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 5)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 5 ? null : 5)}
                    >
                      <h5 className="m-0 p-0 ">
                        Leave Encashment Policy for Paid Leaves
                        <i
                          className={
                            accordion === 5
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 5}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Eligibility :</strong>
                      <ul>
                        Leave encashment applies to employees who have completed a full year of service
                        with the company.
                      </ul>
                      <strong style={{ color: "black" }}>Encashment of Unused Paid Leave :</strong>
                      <ul>
                        Employees are entitled to encash any unused paid leaves
                        (PL) at the end of the year or upon termination of employment. This is generally based on the
                        balance of paid leaves remaining at that time.
                      </ul>
                      <strong style={{ color: "black" }}>Calculation of Encashment :</strong>
                      <ul>
                        The amount of leave encashment is calculated based on the
                        employee's basic salary and the number of unused paid leave days. For example, if an
                        employee has 10 unused paid leave days, and their basic salary is ₹1,000 per day, the total
                        leave encashment will be ₹10,000.
                      </ul>
                      <strong style={{ color: "black" }}>Payment of Encashment :</strong>
                      <ul>
                        Leave encashment is paid out when an employee leaves the company
                        or contractual terms.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 6)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 6 ? null : 6)}
                    >
                      <h5 className="m-0 p-0 ">
                        Attendance Policy
                        <i
                          className={
                            accordion === 6
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 6}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Office Timing :</strong>
                      <ul>
                        Office hours are from 10:00 AM to 7:00 PM. Employees are expected to arrive
                        between 9:45 AM and 10:00 AM.
                      </ul>
                      <strong style={{ color: "black" }}>Working Hours :</strong>
                      <ul>
                        Employees must complete a full 9-hour workday. Failure to complete the
                        required 9-hour workday will result in the day being counted as a <b style={{ color: "black" }}>half day</b>.
                      </ul>
                      <strong style={{ color: "black" }}>Late Arrival & Early Departure :</strong>
                      <ul>
                        If an employee arrives after 10:15 AM, it will count as a half-day
                        after three such occurrences within a calendar month, including early departures.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 7)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 7 ? null : 7)}
                    >
                      <h5 className="m-0 p-0 ">
                        WFH Policy
                        <i
                          className={
                            accordion === 7
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 7}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Work From Home (WFH) :</strong>
                      <ul>
                        No work-from-home option is available to employees under normal
                        circumstances. WFH may only be granted under serious or uncertain conditions, with approval
                        <b style={{ color: "black" }}> exclusively reserved for the Business Head</b>.
                      </ul>
                      <strong style={{ color: "black" }}>WFH Deductions :</strong>
                      <ul>
                        Approved WFH days will include a 30% deduction from the employee’s
                        account as compensation for associated costs.
                      </ul>
                      <strong style={{ color: "black" }}>Unapproved WFH Consequence :</strong>
                      <ul>
                        If an employee takes WFH without prior approval from the
                        Business Head, the day will be treated as unpaid leave.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 8)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 8 ? null : 8)}
                    >
                      <h5 className="m-0 p-0 ">
                        Work Hours & Break Time
                        <i
                          className={
                            accordion === 8
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 8}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Regular Schedule :</strong>
                      <ul>
                        The work schedule is Monday to Saturday, from 10:00 AM to 7:00 PM.
                        Attendance will be checked daily.
                      </ul>
                      <strong style={{ color: "black" }}>Breaks :</strong>
                      <ul>
                        Lunch and tea breaks are included in the work hours and total one hour.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 9)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 9 ? null : 9)}
                    >
                      <h5 className="m-0 p-0 ">
                        Performance Management System
                        <i
                          className={
                            accordion === 9
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 9}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>Review :</strong>
                      <ul>
                        Employee performance is reviewed on a monthly, quarterly, and yearly basis to ensure
                        ongoing development and alignment with company goals.
                      </ul>
                      <strong style={{ color: "black" }}>Performance Improvement Plan (PIP) :</strong>
                      <ul>
                        If an employee consistently fails to meet monthly or
                        quarterly targets, they may be placed on a Performance Improvement Plan (PIP). The PIP will
                        set clear, measurable goals and a time-bound framework for improvement, along with regular
                        feedback and support to help the employee meet expected standards.

                      </ul>
                      <strong style={{ color: "black" }}>Termination for Underperformance :</strong>
                      <ul>
                        In cases where an employee is unable to meet the targets
                        outlined in the PIP or demonstrates ongoing underperformance despite support, termination
                        may be considered as a last resort.
                      </ul>
                      <strong style={{ color: "black" }}>Appraisal Process :</strong>
                      <ul>
                        Appraisals are conducted annually based on overall performance throughout
                        the year. Employees are encouraged to focus on their performance and development, as no
                        additional mid-term or request-based increments will be considered outside of the annual
                        appraisal cycle.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 10)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 10 ? null : 10)}
                    >
                      <h5 className="m-0 p-0 ">
                        Company Asset Policy
                        <i
                          className={
                            accordion === 10
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 10}>
                    <CCardBody>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          Any employee resigning from the company will be held responsible for any damage to
                          company assets during their tenure, with the cost deducted from their final salary.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          In the event of an employee leaving shortly after joining and damaging an assigned
                          asset, the responsibility for payment rests with the departing employee.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Employees are liable for the replacement or repair costs of any company assets lost or
                          damaged while under their possession.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Employees must keep all company assets on the premises and are not allowed to take
                          them off-site without approval, except for SIM cards.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 11)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 11 ? null : 11)}
                    >
                      <h5 className="m-0 p-0 ">
                        Salary Credit Policy For New Joinee
                        <i
                          className={
                            accordion === 11
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 11}>
                    <CCardBody>
                      At <b style={{ color: "black" }}>Center for Business and Technology</b>, new employees joining after the 20th of any
                      month will have their salary for that month credited in the subsequent pay cycle. For
                      instance, if an employee joins on May 21st, their May salary will be credited in July. Those
                      joining on or before the 20th will receive their salary in the next immediate pay cycle.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 12)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 12 ? null : 12)}
                    >
                      <h5 className="m-0 p-0 ">
                        Probation Period
                        <i
                          className={
                            accordion === 12
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 12}>
                    <CCardBody>
                      <strong style={{ color: "black" }}>For Interns & Trainees :</strong>
                      <ul>
                        For interns and trainees who are new to the organisation, there will be a seven-day trial
                        period to allow both parties to assess whether the role and the candidate are a good fit
                        for each other.
                        <li style={{ listStyleType: 'disc' }}>
                          Following this trial period, new joiners will then be subject to a three-month (3 Months)
                          probation period.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>In the event that an employee's performance during this probationary period is deemed
                          unsatisfactory, the employer has the right to terminate their employment.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>It's important to note that regular employees enjoy various protections against arbitrary
                          termination, which are not applicable during the probationary period. However, once an
                          employee passes their probationary period and is confirmed as a regular employee,
                          they will be entitled to these protections as well as any associated benefits.
                        </li>
                      </ul>{' '}
                      <strong style={{ color: "black" }}>For Senior Candidates :</strong>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          As part of our standard employment practices, we implement a probation period for
                          new hires, during which their suitability for the job is assessed.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>For senior candidates, a longer probation period of six months is in place, which
                          provides ample time for the employer to evaluate the candidate's performance and
                          determine their fit within the organisation.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>This extended period is necessary to ensure that senior candidates are capable of
                          performing at a higher level and can contribute to the organisation's success.
                        </li>
                      </ul>
                      <strong style={{ color: "black" }}>Extension of Probation Period :</strong>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          If an employment contract includes a fixed period of probation and the probationary
                          period expires without any rules governing the situation, the employee will continue to
                          serve as a probationer.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>It's important to note that continuing as a probationer beyond the designated period
                          doesn't automatically confer permanent employee status.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>The employer has the right to extend the probation period until they're satisfied that
                          the probationer is suitable for confirmation. As such, a probationer will remain in a
                          probationary status until they're confirmed by the employer.
                        </li>
                        <li style={{ listStyleType: 'disc' }}> To illustrate, the three-month probation period for new joiners can be extended up to
                          six months if deemed necessary by the employer.
                        </li>
                      </ul>
                      <strong style={{ color: "black" }}>Probation Period Termination and Resignation Policy :</strong>
                      <ul>
                        <li style={{ listStyleType: 'disc' }}>
                          During the probation period, an employee's service can be terminated at the employer's
                          discretion. Termination communication will be clear, simple, and non-stigmatic, stating
                          that the probationary services are terminated in accordance with the terms of the
                          appointment.

                        </li>
                        <li style={{ listStyleType: 'disc' }}>If an employee resigns during the probation period, their salary will not be credited and
                          will be forfeited.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 13)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 13 ? null : 13)}
                    >
                      <h5 className="m-0 p-0 ">
                        Disciplinary Action
                        <i
                          className={
                            accordion === 13
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 13}>
                    <CCardBody>
                      <ul>
                        Here are some disciplinary actions that can be taken by the company :<br />
                        <li style={{ listStyleType: 'disc' }}>
                          <b style={{ color: "black" }}>Verbal Warning:</b> This is the least severe form of discipline, typically used for minor
                          policy violations or as an initial step for addressing employee behaviour. A verbal
                          warning is given to the employee, documenting the concern and emphasising the
                          expected behaviour.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <b style={{ color: "black" }}>Written Warning:</b> A written warning is more formal than a verbal warning and is used
                          for repeated or more serious violations. It outlines the specific issue, expectations, and
                          consequences if the behaviour continues.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <b style={{ color: "black" }}>Suspension:</b> Suspension involves temporarily removing an employee from work for a
                          specified period as a consequence for misconduct. It can be paid or unpaid, depending
                          on company policy and the severity of the offence.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <b style={{ color: "black" }}>Demotion:</b> Demotion involves moving an employee to a lower-level position or reducing
                          their responsibilities and salary due to poor performance or serious policy violations.
                          This is typically used when other disciplinary measures have failed to correct the
                          behaviour.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <b style={{ color: "black" }}>Termination:</b> Termination is the most severe form of disciplinary action and involves
                          ending the employment relationship. It is typically used for serious offences, such as
                          theft, harassment, or repeated policy violations.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <b style={{ color: "black" }}>Probation:</b> Probation is a period during which an employee's performance and
                          behaviour are closely monitored. It may include specific conditions or requirements that
                          the employee must meet to remain employed. Failure to meet these conditions can
                          result in further disciplinary action, such as termination.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          <b style={{ color: "black" }}>Counselling or Rehabilitation:</b> In cases where the employee's behaviour is related to
                          personal issues or substance abuse, companies may offer counselling or rehabilitation
                          programs as a disciplinary measure. This aims to support the employee in addressing
                          their underlying problems while holding them accountable for their actions.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 14)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 14 ? null : 14)}
                    >
                      <h5 className="m-0 p-0 ">
                        Termination Policy
                        <i
                          className={
                            accordion === 14
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 14}>
                    <CCardBody>
                      Any and all company employees who are terminated, whether at the end of the contract or
                      prematurely terminated by the company, or have resigned will no longer be subject to the
                      terms and conditions of this policy.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 15)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 15 ? null : 15)}
                    >
                      <h5 className="m-0 p-0 ">
                        Full & Final Payment Policy
                        <i
                          className={
                            accordion === 15
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 15}>
                    <CCardBody>
                      At <b style={{ color: "black" }}>Center for Business and Technology</b>, the Full & Final Payment Policy ensures timely
                      settlement of dues for existing employees. Upon receiving a resignation or termination notice,
                      HR initiates the exit process, including the return of company property and clearance of
                      outstanding dues. HR calculates the final settlement, which Finance then reviews and approves.
                      The final payment is processed within 45 days starting from the last working day via bank
                      transfer. HR provides the departing employee with a detailed settlement statement.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 16)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 16 ? null : 16)}
                    >
                      <h5 className="m-0 p-0 ">
                        Exit Policy{' '}
                        <i
                          className={
                            accordion === 16
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 16}>
                    <CCardBody>
                      <ul>
                        <strong style={{ color: "black" }}>Introduction </strong>
                        When an employee leaves there are a number of actions that need to be undertaken by our
                        organisation.
                      </ul>
                      <ul>
                        <strong style={{ color: "black" }}>Scope </strong>
                        This policy applies to all the staff leaving the employment of Center for Business & Technology,
                        Jaipur (Rajasthan), including staff on temporary or fixed term contracts, who have completed
                        their assignments.

                      </ul>
                      <ul>
                        <strong style={{ color: "black" }}>Objective</strong>
                        <li style={{ listStyleType: 'disc' }}>
                          To ensure a smooth exit for the employee as well as the company.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          To ensure a proper handover from the existing employee.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          To ensure existing employees are treated fairly.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          To tap feedback from existing employee.
                        </li>
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Process:</b> An exit policy consists of a statement which will state how the organisation treats an
                        outgoing employee.
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Resignation by employee:</b>  In the event of an employee expressing his/ her desire to resign
                        being asked to do so the HR department needs to be intimated about their resignation/
                        termination and must tender their resignation in writing at least 1 Month in the minimum
                        period of notice as stipulated in their employment contract and before provide written
                        resignation discussion with the higher authority is necessary for the employees.
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Policy on Employee Exit without Notice:</b> In the event of an employee exiting or leaving the
                        company without providing notice, the company reserves the right to withhold or claim one
                        month's salary as per the terms of the employment agreement. This deduction will be applied
                        to compensate for any inconvenience or disruption caused by the sudden departure.
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Notice Period Buyout Policy:</b> Employees desiring immediate release without serving the
                        designated notice period must provide an amount equivalent to one month's salary as a buyout
                        fee. This provision is reserved for cases of urgent and severe issues.
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Exit Interview:</b> An Exit Interview is a key aspect of the recruitment cycle, as it enables the
                        organisation to elicit information to feed into the recruitment and selection process as well as
                        consideration of retention issues.
                        <li style={{ listStyleType: 'disc' }}>
                          To elicit honest feedback about the employee’s perceptions of management systems
                          and behaviours
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          To assess the effectiveness of team working amongst the employee’s work group
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          To assess the general state of morale in the employee’s work group
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          To assess whether lack of training and career opportunities or other factors are resulting
                          in loss of good employees
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          To determine whether the employee is leaving with positive feelings about the
                          organisation or with unresolved concerns
                        </li>
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Exit Checklist:</b>
                        <li style={{ listStyleType: 'disc' }}>
                          Inform relevant parties
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Prepare paperwork
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Duty transfer
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Conduct exit interview
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Recover company items
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Have a pleasant send-off
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Employment account clean up
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Handover of Assets
                        </li>
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Exit Documents:</b>
                        <li style={{ listStyleType: 'disc' }}>
                          Employees will receive all pending documents within 2 to 3 weeks following their
                          release.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Employees who resign without providing notice will not receive any documents from the
                          company.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          Documents are provided only in the event of buyout or resignation as per company
                          policy.
                        </li>
                      </ul>
                      <ul>
                        <b style={{ color: "black" }}>Exit Questionnaire:</b> Where an employee does not wish to participate in an Exit Interview, or
                        where an Exit Interview has not taken place due to other reasons, the manager should ensure
                        that the employee is given an Exit Questionnaire to complete and submit the same to the HR.
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 17)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 17 ? null : 17)}
                    >
                      <h5 className="m-0 p-0 ">
                        Monitoring
                        <i
                          className={
                            accordion === 17
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 17}>
                    <CCardBody>
                      It is essential for the organisation to devise certain measures in order to monitor
                      the responses and identify any trends which affect the organisation’s recruitment and retention
                      strategies.<br /><br />
                      <b style={{ color: "black" }}>For queries, contact HR at</b> <u>Hr@cbt.ind.in</u>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 18)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                {/* <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 18 ? null : 18)}
                    >
                      <h5 className="m-0 p-0 ">
                        Monitoring
                        <i
                          className={
                            accordion === 18
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 18}>
                    <CCardBody>
                      It is essential for the organisation to devise certain measures in order to monitor
                      the responses and identify any trends which affect the organisation’s recruitment and retention
                      strategies.<br /><br />
                      <b style={{ color: "black" }}>For queries, contact HR at</b> <u>Hr@cbt.ind.in</u>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 19)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard> */}
                <BgvAuthorizationConsent
                  getSignFun={getSignFun}
                  empDetails={empDetails}
                  policyData={policyData}
                />
              </div>
              <br />
            </CCardBody>
            <CCardFooter>
              {!isVerified ? (
                <>
                  {!isOtpSent &&
                    empStatus &&
                    empStatus.emp_policy_status !== 1 && (
                      <button
                        type="button"
                        disabled={
                          policy1 & policy2 & policy3 & policy4 &&
                            policy5 &
                            policy6 &
                            policy7 &
                            policy8 &
                            policy9 &
                            policy10 &
                            policy11 &
                            policy12 &
                            policy13 &
                            policy14 &
                            policy15 &
                            policy16 &
                            policy17 &
                            policy18
                            ? false
                            : true
                        }
                        onClick={sendOtp}
                        className="btn btn-primary mr-3 mt-2 float-right"
                      >
                        Verify
                      </button>
                    )}
                  {isOtpSent && (
                    <>
                      <div className="row">
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            value={otp}
                            onChange={e => setOtp(e.target.value)}
                            placeholder="Enter Otp"
                          />
                        </div>
                        <div className="col-md-3">
                          {empStatus && empStatus.emp_policy_status !== 1 && (
                            <button
                              type="button"
                              disabled={
                                policy1 & policy2 & policy3 & policy4 &&
                                  policy5 &
                                  policy6 &
                                  policy7 &
                                  policy8 &
                                  policy9 &
                                  policy10 &
                                  policy11 &
                                  policy12 &
                                  policy13 &
                                  policy14 &
                                  policy15 &
                                  policy16 &
                                  policy17 &
                                  policy18
                                  ? false
                                  : true
                              }
                              onClick={verifyOTP}
                              className="btn btn-primary mr-3 mt-2 float-right"
                            >
                              Verify & Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {empStatus && empStatus.emp_policy_status !== 1 && (
                    <button
                      type="button"
                      disabled={
                        policy1 & policy2 & policy3 & policy4 &&
                          policy5 &
                          policy6 &
                          policy7 &
                          policy8 &
                          policy9 &
                          policy10 &
                          policy11 &
                          policy12 &
                          policy13 &
                          policy14 &
                          policy15 &
                          policy16 &
                          policy17 &
                          policy18
                          ? false
                          : true
                      }
                      onClick={Policyaccepted}
                      className="btn btn-primary mr-3 mt-2 float-right"
                    >
                      Accept
                    </button>
                  )}
                </>
              )}
            </CCardFooter>
          </CCard>
        )}
      </CCol>
      <ToastContainer />
    </CRow>
  );
};
export default PolicyDetails;
