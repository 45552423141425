import React from 'react';

const ParameterHeader = () => {
  return (
    <div className="cardBox mb-3">
      <div className="row">
        <div className="col-md-2">
          <h4 className="fs-6 fw-bold mb-0 pt-1">Parameters :</h4>
        </div>
        <div className="col-md-10">
          <ul className="parameterList">
            <li>
              Total Present Days
              <button type="button" className="parameterClose">
                <i className="bi bi-x" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ParameterHeader;
