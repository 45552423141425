import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import { toast, ToastContainer } from 'react-toastify';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { Button, Label, FormGroup, CustomInput } from 'reactstrap';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';

import {
  CButton,
  CModal,
  CModalBody,
  CCardFooter,
  CModalHeader,
  CCol,
  CForm,
  CFormGroup,
  CFormText,
  CTextarea,
  CInput,
  CLabel,
  CSelect,
  CRow,
  CModalTitle,
  CModalFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';

const NewSignupForm = (props) => {
  const api = new API();
  const { isOpen, isViewModalOpen, getAllEmp } = props;
  const [allDepartment, setAllDepartment] = useState();
  const [allDepartmentWiseRole, setAllDepartmentWiseRole] = useState();
  const [allEmpForReportingTo, setAllEmpForReportingTo] = useState();
  const [allCity, setAllCity] = useState();
  const [subcompanys, setSubCompany] = useState([]);
  const [company, setCompany] = useState([]);
  const [allWorkingFrom, setAllWorkingFrom] = useState();
  const [location, setLocation] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [roleId, setRoleId] = useState('');
  const [workingFrom, setWorkingFrom] = useState('');
  const [reportedTo, setReportedTo] = useState('');
  const [reportedTo1, setReportedTo1] = useState('');
  const [reportedTo2, setReportedTo2] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [mainCompanyId, setMainCompanyId] = useState({ label: '--Select Company--', value: '' });
  const [signupform, setsignupform] = useState({
    is_pf: 0,
    is_esi: 0,
    bonus: 0,
    emp_id: '',
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    joining_date: '',
  });
  useEffect(() => {
    getAllDepartment();
    getAllEmpForReportingTo();
    getAllWorkingFrom();
    getEntities();
  }, []);

  const getAllDepartment = async () => {
    let result = await api.get(config.getAllDepartmentList);
    if (result && result.code == 200) {
      setAllDepartment(result?.data);
    }
  };

  const getAllWorkingFrom = async () => {
    let result = await api.get(config.getAllWorkingFrom);
    if (result && result.code === 200) {
      setAllWorkingFrom(result.data);
    }
  };
  const subcompany = async (item) => {
    if (item) {
      let data = {
        companyId: item
      }
      let result = await api.get(config.subCompany, true, data)
      if (result && result.code === 200) {
        setSubCompany(result?.data)
        // setEmpData1({ ...empData1, ['parent_company_id']: item });
      } else {
        toast.warning(result && result.message)
      }
    }
  }
  const getEntities = async () => {
    let result = await api.get(config.getentities)
    if (result && result.code == 200) {
      setCompany(result.data)
    } else {
      toast.warning(result && result.message)
    }
  }
  const getAllDesignationByDpt = async deptId => {
    let data = {
      department_id: deptId,
    };
    let result = await api.get(config.getDepartmentWiseRoles, true, data);

    if (result && result.code == 200) {
      setAllDepartmentWiseRole(result.data);
    }
  };

  const getAllEmpForReportingTo = async deptId => {
    let result = await api.get(config.getAllEmployeeList);

    if (result && result.code == 200) {
      setAllEmpForReportingTo(result.data);
    }
  };

  const onInputChange = async e => {
    const { name } = e.target;
    if (name === 'is_pf' || name === 'is_esi' || name === 'bonus') {
      let eligility = e.target.checked === true ? 1 : 0;
      setsignupform({ ...signupform, [name]: eligility });
    } else {
      setsignupform({ ...signupform, [name]: e.target.value });
    }
  };

  const changeDepartment = (e) => {
    setDepartmentId(e);
    getAllDesignationByDpt(e?.id);
  }

  const getEmpId = async (item) => {
    if (item) {
      let data = {
        emp_id_start: item
      }
      let result = await api.get(config.getEmpIdByCompany, true, data)
      if (result && result.code === 200) {
        setsignupform({ ...signupform, ['emp_id']: result?.data });
      } else {
        toast.warning('Unable to fetch emp_id');
      }
    }
  }

  const changeEntity = (e) => {
    setMainCompanyId({ label: e.name, value: e.name })
    getEmpId(e?.emp_id_start);
    subcompany(e?.id);
  }


  const Submitformdata = async () => {
    let data = {
      is_pf: signupform.is_pf || 0,
      is_esi: signupform.is_esi || 0,
      bonus: signupform.bonus || 0,
      emp_id: signupform?.emp_id || '',
      first_name: signupform.first_name || '',
      last_name: signupform.last_name || '',
      mobile: signupform.mobile || '',
      email: signupform.email || '',
      joining_date: signupform.joining_date || '',
      location: location?.name || '',
      department_id: departmentId?.id || '',
      role_id: roleId?.id || '',
      reported_to: reportedTo?.id || '',
      // reported_to1: reportedTo1?.id || '',
      // reported_to2 : reportedTo2?.id || '',
      working_from: workingFrom?.category_value || '',
      company_id: companyId?.id || '',
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to submit details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.createEmployeeByAdmin, data);
        if (result && result.code == 200) {
          toast.success(result?.message);
          isViewModalOpen(!isOpen);
          getAllEmp();
        }
      }
    });
  };
  const getAllCity = () => {
    let allCity = City.getCitiesOfCountry('IN');
    allCity.push(
      { name: 'Neemrana', countryCode: 'IN', stateCode: 'RJ' });
    setAllCity(allCity);
  };
  useEffect(() => {
    getAllCity();
  }, []);
  useEffect(() => {
    if (isOpen == true) {
      setsignupform({
        is_pf: 0,
        is_esi: 0,
        bonus: 0,
        first_name: '',
        last_name: '',
        mobile: '',
        email: '',
        joining_date: '',
        emp_id: '',
      })
      setLocation('')
      setDepartmentId('')
      setRoleId('')
      setReportedTo2('')
      setReportedTo1('')
      setReportedTo('')
      setWorkingFrom('')
      getEmpId('')
      setCompanyId('')
      setMainCompanyId({ label: '--Select Company--', value: '' });
      setSubCompany([])
    }

  }, [isOpen])

  return (
    <div className="">
      <CModal
        show={isOpen}
        onClose={() => isViewModalOpen(!isOpen)}
        size="lg">
        <CModalHeader closeButton>
          <CModalTitle>Add Details</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs="12">
              <CModalBody>
                <AvForm
                  action=""
                  method="post"
                  encType="multipart/form-data"
                  className="form-horizontal"
                  onValidSubmit={Submitformdata}
                >
                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Company Name <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Company--"
                        onChange={(e) => changeEntity(e)}
                        value={mainCompanyId}
                        options={
                          company &&
                          company.length > 0 &&
                          company.map(val => {
                            val.label =
                              val.name;
                            val.value = val.name;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Employee ID <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvInput
                        name="emp_id"
                        id="Employee ID"
                        value={signupform?.emp_id}
                        // required
                        disabled
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Consider PF</CLabel>
                    </CCol>
                    <CCol md="8">
                      <AvField
                        type="checkbox"
                        name="is_pf"
                        id="Consider PF"
                        checked={signupform?.is_pf === 1 ? true : false}
                        onClick={e => onInputChange(e)}
                        label="PF"
                      ></AvField>
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Consider ESI</CLabel>
                    </CCol>
                    <CCol md="8">
                      <AvField
                        type="checkbox"
                        name="is_esi"
                        id="Consider ESI"
                        checked={signupform?.is_esi === 1 ? true : false}
                        onClick={e => onInputChange(e)}
                        label="ESI"
                      ></AvField>
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Consider Bonus</CLabel>
                    </CCol>
                    <CCol md="8">
                      <AvField
                        type="checkbox"
                        name="bonus"
                        id="Consider Bonus"
                        checked={signupform?.bonus === 1 ? true : false}
                        onClick={e => onInputChange(e)}
                        label="Bonus"
                      ></AvField>
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">First Name <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField
                        name="first_name"
                        value={signupform?.first_name || ''}
                        id="First Name"
                        placeholder="Enter First Name"
                        onChange={e => onInputChange(e)}
                        // required
                        validate={{
                          // required: {value: true, errorMessage: 'First name is required'},
                          pattern: { value: /^[a-zA-Z\s]+$/, errorMessage: 'First name field should be allowed alphabetic characters. ' },
                          maxLength: { value: 16, errorMessage: 'Your name must 16 characters' }
                        }}
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Last Name <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField
                        name="last_name"
                        value={signupform?.last_name || ''}
                        id="Last Name"
                        onChange={e => onInputChange(e)}
                        // required
                        placeholder="Enter Last Name"
                        validate={{
                          // required: {value: true, errorMessage: 'Last name is required'},
                          pattern: { value: /^[a-zA-Z\s]+$/, errorMessage: 'Last name field should be allowed alphabetic characters. ' },
                          maxLength: { value: 16, errorMessage: 'Your name must 16 characters' }
                        }}
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Mobile No. <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField
                        type="number"
                        name="mobile"
                        value={signupform?.mobile || ''}
                        id="Mobile No."
                        placeholder="Enter Mobile No."
                        onChange={e => onInputChange(e)}
                        // required
                        maxLength="10"
                        validate={{
                          // required: {value: true, errorMessage: 'Last name is required'},
                          pattern: { value: /^(?!.*(\d)\1{4})[6-9]\d{9}$/, errorMessage: 'Please enter a valid mobile number, It must be of 10 digits and begin with 6,7,8 or 9. ' },
                          maxLength: { value: 10, errorMessage: '' }
                        }}
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Email ID <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField
                        name="email"
                        value={signupform?.email || ''}
                        id="Email ID"
                        onChange={e => onInputChange(e)}
                        // required
                        placeholder="Enter Email ID"
                        // required
                        validate={{
                          // required: {value: true, errorMessage: 'Please enter a name'},
                          pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,4}$/, errorMessage: 'Pleace enter a valid email ID.' },

                        }}

                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Joining Date <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <AvField
                        type="date"
                        name="joining_date"
                        value={signupform?.joining_date}
                        id="Joining Date"
                        onChange={e => onInputChange(e)}
                        // required
                        placeholder="Select Joining Date"
                        validate={{
                          date: { format: 'DD/MM/YYYY', errorMessage: 'pleace enter a valid joining date formate DD/MM/YYYY' },
                          // required: {value: true, errorMessage: 'Joining Date required'},
                        }}
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Location <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">

                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Location--"
                        onChange={setLocation}
                        value={location}
                        options={
                          allCity &&
                          allCity.length > 0 &&
                          allCity.map(val => {
                            val.label =
                              val.name;
                            val.value = val.name;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Department <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Department--"
                        onChange={e => changeDepartment(e)}
                        value={departmentId}
                        options={
                          allDepartment &&
                          allDepartment.length > 0 &&
                          allDepartment.map(val => {
                            val.label =
                              val.name;
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Designation <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Designation--"
                        onChange={setRoleId}
                        value={roleId}
                        options={
                          allDepartmentWiseRole &&
                          allDepartmentWiseRole.length > 0 &&
                          allDepartmentWiseRole.map(val => {
                            val.label =
                              val.role_name;
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Reporting Manager <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">

                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Reporting Manager--"
                        onChange={setReportedTo}
                        value={reportedTo}
                        options={
                          allEmpForReportingTo &&
                          allEmpForReportingTo.length > 0 &&
                          allEmpForReportingTo.map(val => {
                            val.label = val.first_name + ' ' + val.last_name + ' (' + val.emp_id + ')';
                            val.value = val.id;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  {/* <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Reporting Manager 2<span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                    <Select
                                  required
                                  style={{ fontSize: '14px' }}
                                  placeholder="--Select Reporting Manager 2--"
                                  onChange={setReportedTo1}
                                  value={reportedTo1}
                                  options={
                                    allEmpForReportingTo &&
                                    allEmpForReportingTo.length > 0 &&
                                    allEmpForReportingTo.map(val => {
                                      val.label = val.first_name + ' ' + val.last_name + ' (' + val.emp_id + ')';
                                      val.value = val.id;
                                      return val;
                                    })
                                  }
                                />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Reporting Manager 3<span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                    <Select
                                  required
                                  style={{ fontSize: '14px' }}
                                  placeholder="--Select Reporting Manager 3--"
                                  onChange={setReportedTo2}
                                  value={reportedTo2}
                                  options={
                                    allEmpForReportingTo &&
                                    allEmpForReportingTo.length > 0 &&
                                    allEmpForReportingTo.map(val => {
                                      val.label = val.first_name + ' ' + val.last_name + ' (' + val.emp_id + ')';
                                      val.value = val.id;
                                      return val;
                                    })
                                  }
                                />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup> */}

                  <CFormGroup row>
                    <CCol md="4">
                      <CLabel htmlFor="">Working From <span className="required-field">*</span></CLabel>
                    </CCol>
                    <CCol xs="12" md="8">
                      <Select
                        required
                        style={{ fontSize: '14px' }}
                        placeholder="--Select Working From--"
                        onChange={setWorkingFrom}
                        value={workingFrom}
                        options={
                          allWorkingFrom &&
                          allWorkingFrom.length > 0 &&
                          allWorkingFrom.map(val => {
                            val.label = val.category_key;
                            val.value = val.category_value;
                            return val;
                          })
                        }
                      />
                    </CCol>
                    <CCol
                      xs="12"
                      md="5"
                      style={{ position: 'relative', paddingBottom: '10px' }}
                    >
                    </CCol>
                  </CFormGroup>
                  <Button
                    className="mr-2"
                    type="submit"
                    size="sm"
                    color="primary"
                  >
                    Submit
                  </Button>
                </AvForm>
              </CModalBody>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={() => isViewModalOpen(!isOpen)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton>{' '}
        </CModalFooter>
      </CModal>
      <ToastContainer />
    </div>
  );
};
export default NewSignupForm;
