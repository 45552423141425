const getInitInfo = (custPort = '') => {
  const { hostname, origin } = window.location;
  let appPort = custPort || 3001;
  let appBaseUri = `http://${hostname}:${appPort}`;
  let appBaseUriChat = `https://chat.glueple.com:8080`;
  let isLocalhost = true;

  if (hostname === 'glueple.com') {
    isLocalhost = false;
    appPort = 3001;
    appBaseUri = `${origin}:${appPort}`;
    appBaseUriChat = `https://chat.glueple.com:8080`;
  } else if (hostname === 'cbt.glueple.com') {
    isLocalhost = false;
    appPort = 3021;
    appBaseUri = `${origin}:${appPort}`;
    appBaseUriChat = `https://cbtchat.glueple.com:8080`;
  }
  // localStorage.setItem("appPort", appPort);
  // localStorage.setItem("appBaseUri", appBaseUri);

  console.log({
    isLocalhost,
    appPort,
    appBaseUri,
    hostname,
    origin,
    appBaseUriChat,
  });
  return { isLocalhost, appPort, appBaseUri, appBaseUriChat };
};

const custPort = '';
const { isLocalhost, appPort, appBaseUri, appBaseUriChat } = getInitInfo(
  custPort,
);

let baseUri = '';
let baseUriChat = appBaseUriChat;
if (isLocalhost) {
  // baseUri = 'http://localhost:3021';
   baseUri = 'https://cbt.glueple.com:3021';

  // baseUri = 'http://localhost:3003';
  // baseUri = 'https://demo.glueple.com:3003';

  //baseUriChat = 'http://localhost:8080';
} else {
  baseUri = appBaseUri;
  baseUriChat = appBaseUriChat;
}

export const Base_URI = baseUri;
export const Base_URI_Chat = baseUriChat;

export const config = {
  appPort,
  appBaseUri,
  login: `${Base_URI}/employee/emplogin`,
  profile: `${Base_URI}/employee/getEmployeeProfileDetails`,
  savedetails: `${Base_URI}/employee/fillPersonalDetails`,
  familydetails: `${Base_URI}/employee/fillFamilyDetails`,
  bankdetails: `${Base_URI}/employee/fillBankDetails`,
  edudetails: `${Base_URI}/employee/fillEducationalDetails`,
  deleteEducationalDetails: `${Base_URI}/employee/deleteEducationalDetails`,
  deleteWorkExperienceDetails: `${Base_URI}/employee/deleteWorkExperienceDetails`,
  socialdetails: `${Base_URI}/employee/fillSocialDetails`,
  referencedetails: `${Base_URI}/employee/fillReferenceDetails`,
  fillWorkExeperienceDetails: `${Base_URI}/employee/fillWorkExeperienceDetails`,
  updatepersonaldetails: `${Base_URI}/employee/updatePersonalDetails`,
  updatefamilydetails: `${Base_URI}/employee/updateFamilyDetails`,
  updatebankdetails: `${Base_URI}/employee/updateBankDetails`,
  updatesocialdetails: `${Base_URI}/employee/updateSocialDetails`,
  updatereferencedetails: `${Base_URI}/employee/updateReferenceDetails`,
  updateeducationaldetails: `${Base_URI}/employee/updateEducationalDetails`,
  getWorkExperienceDetails: `${Base_URI}/employee/getWorkExperienceDetails`,
  getEmployeePersonalDetails: `${Base_URI}/employee/getEmployeePersonalDetails`,
  getEmployeeFamilyDetails: `${Base_URI}/employee/getEmployeeFamilyDetails`,
  getEmployeeEducationalDetails: `${Base_URI}/employee/getEmployeeEducationalDetails`,
  getBankDetailsByIfsc: `${Base_URI}/employee/getBankDetailsByIfsc`,
  getEmployeeBankDetails: `${Base_URI}/employee/getEmployeeBankDetails`,
  getEmployeeSocialDetails: `${Base_URI}/employee/getEmployeeSocialDetails`,
  getEmployeeReferenceDetails: `${Base_URI}/employee/getEmployeeReferenceDetails`,
  changepassword: `${Base_URI}/employee/changeEmployeePassword`,
  employeePolicy: `${Base_URI}/employee/employeePolicy`,
  allDataforPolicy: `${Base_URI}/hr/allDataforPolicy`,
  // acceptofferletter: `${Base_URI}/employee/employeeOfferLetter`,
  acceptOfferLetter: `${Base_URI}/employee/acceptOfferLetter`,
  declineOfferLetter: `${Base_URI}/employee/declineOfferLetter`,
  detailstatus: `${Base_URI}/employee/detailStatus`,
  getInterviewDetailForInterviewer: `${Base_URI}/employee/getInterviewDetailForInterviewer`,
  getInterviewCompletedCandidates: `${Base_URI}/employee/getInterviewCompletedCandidates`,
  getCandidateDetailById: `${Base_URI}/employee/getCandidateDetailById`,
  addInterviewRoundAssesment: `${Base_URI}/employee/addInterviewRoundAssesment`,
  getCandidateCtcJoiningDetail: `${Base_URI}/employee/getCandidateCtcJoiningDetail`,
  getRouteKey: `${Base_URI}/employee/getRouteKey`,
  offerAcceptedFinalReported: `${Base_URI}/employee/offerAcceptedFinalReported`,
  approveReportedCandidate: `${Base_URI}/employee/approveReportedCandidate`,
  sendOtp: `${Base_URI}/employee/sendOtp`,
  verifyOtp: `${Base_URI}/employee/verifyOtp`,
  sendOtpMailSMS: `${Base_URI}/employee/send-otp-mail-sms`,
  verifyOtpMailSMS: `${Base_URI}/employee/verify-otp-mail-sms`,
  forgotPassword: `${Base_URI}/employee/forgotPassword`,
  getEmployeeProfileDetailStatus: `${Base_URI}/employee/getEmployeeProfileDetailStatus`,
  verfiyAadhar: `${Base_URI}/employee/verfiyAadhar`,
  getVerifiedAadharCardDetails: `${Base_URI}/employee/getVerifiedAadharCardDetails`,
  tokenVerify: `${Base_URI}/employee/tokenVerify`,
  getRequiredDocumentDetails: `${Base_URI}/employee/getRequiredDocumentDetails`,
  mrfdynamicdetailsform: `${Base_URI}/mrf/getMrfDetailsForFillingDynamic`,
  updateVacancyData: `${Base_URI}/mrf/updateVacancyData`,
  updateAuthDetail: `${Base_URI}/mrf/updateAuthDetail`,
  updateJdVacancy: `${Base_URI}/mrf/updateJdVacancy`,
  mrfcreatevacancy: `${Base_URI}/mrf/createVacancy`,
  mrfcreatereplacement: `${Base_URI}/mrf/createReplacement`,
  mrfgetvacancy: `${Base_URI}/mrf/getCreatedVacancy`,
  mrfgetreplacement: `${Base_URI}/mrf/getCreatedReplacement`,
  getreplacementemployee: `${Base_URI}/mrf/getEmployeeDetailForMrfReplacement`,
  firstmrfrecords: `${Base_URI}/mrf/getPendingMrfForJd`,
  submitJobDescription: `${Base_URI}/mrf/createJdForMrf`,
  interviewingauthority: `${Base_URI}/mrf/nameForInterviewInMRF`,
  getmrfrecordforinterviewauthority: `${Base_URI}/mrf/getInterviewAuthorityDetail`,
  downloadRawDumpForLevels: `${Base_URI}/mrf/downloadRawDumpForLevels`,
  checkReportingManager: `${Base_URI}/mrf/checkReportingManager`,
  get_mrf_details_for_hr_dash_count: `${Base_URI}/mrf/get_mrf_details_for_hr_dash_count`,
  get_mrf_detatils_data_hr_dash: `${Base_URI}/mrf/get_mrf_detatils_data_hr_dash`,
  view_candidate_on_mrf: `${Base_URI}/mrf/view_candidate_on_mrf`,
  findMRFdata: `${Base_URI}/mrf/findMRFdata`,
  candidateOnMRF: `${Base_URI}/mrf/candidateOnMRF`,
  updatemrf: `${Base_URI}/mrf/updateMrf`,
  deletemrf: `${Base_URI}/mrf/deleteMrf`,
  //submitmrf:`${Base_URI}/mrf/getMrfDataById`,
  submitmrf: `${Base_URI}/mrf/getMrfDataById`,
  getsubmittedmrf: `${Base_URI}/mrf/showSubmittedMrfData`,
  getapprovedmrf: `${Base_URI}/mrf/allAssignedMRf`,
  createInterviewAuthorityForMrf: `${Base_URI}/mrf/createInterviewAuthorityForMrf`,
  ApprovedMrfByManager: `${Base_URI}/mrf/ApprovedMrfByManager`,
  getMrfStatus: `${Base_URI}/mrf/getMrfStatus`,
  updateHrShortList: `${Base_URI}/employee_referral/updateHrShortList`,
  referraljobrecords: `${Base_URI}/employee_referral/getJdDetailForMrf`,
  applyJob: `${Base_URI}/employee_referral/applyJob`,
  updateRejectMrf: `${Base_URI}/employee_referral/updateRejectMrf`,
  applyJobRef: `${Base_URI}/employee_referral/applyJobRef`,
  updateMrfType: `${Base_URI}/employee_referral/updateMrfType`,
  getAllApplyJobDetails: `${Base_URI}/employee_referral/getAllApplyJobDetails`,
  getallMRF_IJP_REF: `${Base_URI}/mrf/getallMRF_IJP_REF`,
  moveCandidate: `${Base_URI}/mrf/move-candidate`,
  updateCandidateDetails: `${Base_URI}/mrf/update-candidate-detail`,
  // addnewdepartment: `${Base_URI}/department/createDepartment`,
  createDepartment: `${Base_URI}/department/createDepartment`,
  createRole: `${Base_URI}/roles/createRole`,
  uploadEmployeeAttendanceData: `${Base_URI}/admin/uploadEmployeeAttendanceData`,
  getAllClosedMRFForHRManagerDashboard: `${Base_URI}/mrf/getAllClosedMRFForHRManagerDashboard`,
  excelForAttendance: `${Base_URI}/admin/excelForAttendance`,
  showHikvisionAttendance: `${Base_URI}/admin/showHikvisionAttendance`,
  getAttendanceforHikVision: `${Base_URI}/admin/getAttendanceforHikVision`,
  employeeAttendanceCorrectionHikvision: `${Base_URI}/admin/employeeAttendanceCorrectionHikvision`,
  getAllAttendanceLog: `${Base_URI}/admin/getAllAttendanceLog`,
  approveRejectCorrection: `${Base_URI}/admin/approveRejectCorrection`,
  esat_api: `${Base_URI}/admin/esat_api`,
  masterData: `${Base_URI}/admin/masterData`,
  JdMRF: `${Base_URI}/mrf/JdMRF`,
  downloadHikvisionAttendanceData: `${Base_URI}/admin/downloadHikvisionAttendanceData`,
  sendMailForApproval: `${Base_URI}/mrf/sendMailForApproval`,
  countOfCandidate: `${Base_URI}/mrf/countOfCandidate`,
  approveRejectCandidate: `${Base_URI}/mrf/approveRejectCandidate`,
  dataforMRF: `${Base_URI}/mrf/dataforMRF`,
  copyMRF: `${Base_URI}/mrf/copyMRF`,
  getdepartment: `${Base_URI}/department/getDepartment`,
  deletedepartment: `${Base_URI}/department/deleteDepartment`,
  getroles: `${Base_URI}/department/getDepturl`,
  deleterole: `${Base_URI}/roles/deleteRole`,
  addnewrole: `${Base_URI}/roles/createRole`,
  getallrecords: `${Base_URI}/department/getRoleDept`,
  acceptapprovalmrf: `${Base_URI}/mrf/approveMrfDataById`,
  getJobs: `${Base_URI}/mrf/getJobs`,
  declineapprovalmrf: `${Base_URI}/mrf/declineMrfForManager`,
  getJdForMrf: `${Base_URI}/mrf/getJdForMrf`,
  getAllEmpIdForReplacement: `${Base_URI}/mrf/getAllEmpIdForReplacement`,
  recreateCandidateOnMrf: `${Base_URI}/mrf/recreateCandidateOnMrf`,
  reviewrole: `${Base_URI}/roles/reviewRole`,
  allroutekeys: `${Base_URI}/roles/routeKeys`,
  EmployeePanel_uplodeDocFile: `${Base_URI}/employee/uplodeDocFile`,
  getDocuumentStatus: `${Base_URI}/employee/getDocuumentStatus`,
  getEmployeeDocuments: `${Base_URI}/employee/getEmployeeDocuments`,
  uploadEmployeeProfilePic: `${Base_URI}/employee/uploadEmployeeProfilePic`,
  getEmployeeProfilePic: `${Base_URI}/employee/getEmployeeProfilePic`,
  uplodeDocFile: `${Base_URI}/employee/uplodeDocFile`,
  EmployeePanel_deleteDocFile: `${Base_URI}/employee/deleteDocFile`,
  ctcCalculator: `${Base_URI}/ctc/ctcCalculator`,
  ctcGenerator: `${Base_URI}/admin/ctcGenerator`,
  mrfAssignToHr: `${Base_URI}/hr/hrAssignee`,
  skip_aadhar: `${Base_URI}/hr/skip_aadhar`,
  assignMrfToRecruiter: `${Base_URI}/hr/assignMrfToRecruiter`,
  getMrfReceivedforHrManager: `${Base_URI}/hr/getMrfReceivedforHrManager`,
  getRejectedMrfByHrManager: `${Base_URI}/hr/getRejectedMrfByHrManager`,
  totalAssignedMrfToRecruiter: `${Base_URI}/hr/getTotalAssignedMrfToRecruiter`,
  getMrfById: `${Base_URI}/hr/getMrfById`,
  getSelectedCandidateByHrRecruiter: `${Base_URI}/hr/getSelectedCandidateByHrRecruiter`, //to be continue
  approvalForSelectedCandidate: `${Base_URI}/hr/approvalForSelectedCandidate`,
  declineSelectedCandidate: `${Base_URI}/hr/declineSelectedCandidate`,
  profileBank: `${Base_URI}/hr/profileBank`,
  rejectMrfByHrManager: `${Base_URI}/hr/rejectMrfByHrManager`,
  getAllCandidate: `${Base_URI}/hrRecruiter/getAllCandidate`,
  sendCredentails: `${Base_URI}/hrRecruiter/sendCredentails`,
  // Recuriter panel
  showAssignedMrfForRecruiter: `${Base_URI}/hrRecruiter/showAssignedMrfForRecruiter`,
  createCandidate: `${Base_URI}/hrRecruiter/createCandidate`,
  createCandidateWithoutMrf: `${Base_URI}/hrRecruiter/createCandidateWithoutMrf`,
  getCreatedCandidate: `${Base_URI}/hrRecruiter/getCandidateForMRf`,
  selectCandidate: `${Base_URI}/hrRecruiter/selectCandidate`,
  getApprovedCandidateByHrManager: `${Base_URI}/hrRecruiter/getApprovedCandidateByHrManager`,
  sendInterviewLetter: `${Base_URI}/hrRecruiter/sendInterviewLetter`,
  // sendInterviewLetter: `${Base_URI}/hrRecruiter/sendInterviewLetter`,
  sendOfferLetterToCandidate: `${Base_URI}/hrRecruiter/sendOfferLetterToCandidate`,
  updateOfferLetterStatus: `${Base_URI}/hrRecruiter/updateOfferLetterStatus`,
  getInterviewAssigneeDetail: `${Base_URI}/hrRecruiter/getInterviewAssigneeDetail`,
  selectedCandidateForOps: `${Base_URI}/hrRecruiter/selectedCandidateForOps`,
  rejectedCandidateForOps: `${Base_URI}/hrRecruiter/rejectedCandidateForOps`,
  totalOfferIssued: `${Base_URI}/hrRecruiter/totalOfferIssued`,
  totalOfferAcceptedFromCandidate: `${Base_URI}/hrRecruiter/totalOfferAcceptedFromCandidate`,
  totalOfferRejectedFromCandidate: `${Base_URI}/hrRecruiter/totalOfferRejectedFromCandidate`,
  totalCandidateJoined: `${Base_URI}/hrRecruiter/totalCandidateJoined`,
  assignCandidateIntervToInterviewer: `${Base_URI}/hrRecruiter/assignCandidateIntervToInterviewer`,
  candidateAssesmentDetails: `${Base_URI}/hrRecruiter/candidateAssesmentDetails`,
  getCandidateResume: `${Base_URI}/hrRecruiter/getCandidateResume`,
  new_joiners_candidate_for_hr_recruiter: `${Base_URI}/hrRecruiter/new_joiners_candidate_for_hr_recruiter`,
  updateEmpIdByHR: `${Base_URI}/hrRecruiter/updateEmpIdByHR`,
  branches: `${Base_URI}/hrRecruiter/branches`,
  generateEmployeeId: `${Base_URI}/hrRecruiter/generateEmployeeId`,
  saveMinMaxCtcForMRF: `${Base_URI}/hrRecruiter/save-min-max-ctc-for-mrf`,
  updateCtcForMRF: `${Base_URI}/hrRecruiter/update-max-ctc-for-mrf`,
  getMrfForHod: `${Base_URI}/hrRecruiter/get-mrf-for-hod`,
  getInterviewRoundForMrf: `${Base_URI}/mrf/getInterviewRoundForMrf`,
  sources: `${Base_URI}/hr/sources`,
  updateMRF: `${Base_URI}/hr/updateMRF`,
  candidateForAppointmentLetter: `${Base_URI}/hrRecruiter/candidate-for-appointment-letter`,
  sendAppointmentLetterToCandidate: `${Base_URI}/hrRecruiter/send-appointment-letter-to-candidate`,

  // ______________________________________________________________________________

  applyForSaperation: `${Base_URI}/employee_separation/applyForSaperation`,
  getEmpSaperationReq: `${Base_URI}/employee_separation/getEmpSaperationReq`,
  getBasicDetailForSeparation: `${Base_URI}/employee_separation/getBasicDetailForSeparation`,
  getApprovedSaperation: `${Base_URI}/employee_separation/getApprovedSaperation`,
  getRejectedSaperation: `${Base_URI}/employee_separation/getRejectedSaperation`,
  getAppliedEmpSaperationReq: `${Base_URI}/employee_separation/getAppliedEmpSaperationReq`,
  getEmployeeClearanceFormStatusDetail: `${Base_URI}/employee_separation/getEmployeeClearanceFormStatusDetail`,
  fillEmployeeClearanceForm: `${Base_URI}/employee_separation/fillEmployeeClearanceForm`,
  getEmloyeeClearanceFormDetailForHigherAuth: `${Base_URI}/employee_separation/getEmloyeeClearanceFormDetailForHigherAuth`,
  getEmloyeeClearanceFormDetailForHigherAuthAfterSubmited: `${Base_URI}/employee_separation/getEmloyeeClearanceFormDetailForHigherAuthAfterSubmited`,
  fillManagerClearanceForm: `${Base_URI}/employee_separation/fillManagerClearanceForm`,
  fillAdminClearanceForm: `${Base_URI}/employee_separation/fillAdminClearanceForm`,
  fillItSupportClearanceForm: `${Base_URI}/employee_separation/fillItSupportClearanceForm`,
  fillFinanceClearanceForm: `${Base_URI}/employee_separation/fillFinanceClearanceForm`,
  fillHrClearanceForm: `${Base_URI}/employee_separation/fillHrClearanceForm`,
  getSubmittedClearanceFormView: `${Base_URI}/employee_separation/getSubmittedClearanceFormView`,
  getFullNFinalRequest: `${Base_URI}/employee_separation/getFullNFinalRequest`,
  getFullNFinalRequestClose: `${Base_URI}/employee_separation/getFullNFinalRequestClose`,
  getSeparationAccessKeyword: `${Base_URI}/employee_separation/getSeparationAccessKeyword`,
  employeeSeparationReassign: `${Base_URI}/employee_separation/employeeSeparationReassign`,
  acceptSeparationRejection: `${Base_URI}/employee_separation/acceptSeparationRejection`,
  globalClearanceEmployeeDetails: `${Base_URI}/employee_separation/globalClearanceEmployeeDetails`,
  empSeparationFullDetailsRowDump: `${Base_URI}/employee_separation/empSeparationFullDetailsRowDump`,
  getAllEmployeeClearanceFormDetails: `${Base_URI}/employee_separation/getAllEmployeeClearanceFormDetails`,
  downloadRawDumpForEmployeeSeparation: `${Base_URI}/employee_separation/downloadRawDumpForEmployeeSeparation`,
  fillEmployeeExitInterivewForm: `${Base_URI}/employee_separation/fillEmployeeExitInterivewForm`,
  nonVoluntaryDetails: `${Base_URI}/employee_separation/nonVoluntaryDetails`,
  getAllSeparation: `${Base_URI}/employee_separation/get-all-separation`,
  updateSeprationData: `${Base_URI}/employee_separation/update-separation`,
  generateExperienceLetter: `${Base_URI}/employee_separation/generate-experience-letter`,
  // ________________________________asset management______________________
  requestForAssets: `${Base_URI}/employee_asset_management/requestForAssets`, //post
  getAllAssetRequestForEmployee: `${Base_URI}/employee_asset_management/getAllAssetRequestForEmployee`, //get
  getRejectedAssetRequestForEmployee: `${Base_URI}/employee_asset_management/getRejectedAssetRequestForEmployee`, //get
  getAssetCatList: `${Base_URI}/employee_asset_management/getAssetCatList`, //get
  getAllAssetReqForApproval: `${Base_URI}/employee_asset_management/getAllAssetReqForApproval`, //get
  updateAssetReqStatus: `${Base_URI}/employee_asset_management/updateAssetReqStatus`, //get
  deleteAsset: `${Base_URI}/employee_asset_management/deleteAsset`, //get
  inActiveAsset: `${Base_URI}/employee_asset_management/inactive-asset`, //get
  updateEmailIdByITSupport: `${Base_URI}/employee_asset_management/updateEmailIdByITSupport`,
  addRemarkForAsset: `${Base_URI}/employee_asset_management/addRemarkForAsset`,
  // It-Support
  getAllAssetRequestFromEmployee: `${Base_URI}/employee_asset_management/getAllAssetRequestFromEmployee`, //get
  rejectEmployeeAssetRequest: `${Base_URI}/employee_asset_management/rejectEmployeeAssetRequest`, //post
  fillAssetsUndertakenForm: `${Base_URI}/employee_asset_management/fillAssetsUndertakenForm`, //post
  updateFilledAssetsUndetakenForm: `${Base_URI}/employee_asset_management/updateFilledAssetsUndetakenForm`, //put
  getFilledAssetsUndertakenForm: `${Base_URI}/employee_asset_management/getFilledAssetsUndertakenForm`, //get
  getAllEmployeeAssetsAssignedDetails: `${Base_URI}/employee_asset_management/getAllEmployeeAssetsAssignedDetails`,
  closeUndertakenForm: `${Base_URI}/employee_asset_management/closeUndertakenForm`,
  getITAssetReqList: `${Base_URI}/employee_asset_management/get-IT-asset-req-list`,
  userAssignAssetList: `${Base_URI}/employee_asset_management/userAssignAssetList`,
  // userAssignAssetList  : `${Base_URI}/employee_asset_management/userAssignAssetList`,
  assignAsset: `${Base_URI}/employee_asset_management/assignAsset`,
  getInventoryList: `${Base_URI}/employee_asset_management/getInventoryList`,
  assetAssignUserList: `${Base_URI}/employee_asset_management/assetAssignUserList`,
  assetRelease: `${Base_URI}/employee_asset_management/assetRelease`,
  addAssetBulk: `${Base_URI}/employee_asset_management/addAssetBulk`,
  addAssetOneByOne: `${Base_URI}/employee_asset_management/addAssetOneByOne`,
  new_joiners_candidate: `${Base_URI}/employee_asset_management/new_joiners_candidate`,
  getAllIDCardDetails: `${Base_URI}/employee_asset_management/getAllIDCardDetails`,
  assignToAdmin: `${Base_URI}/employee_asset_management/assignToAdmin`,
  updateDetailsforIDCard: `${Base_URI}/employee_asset_management/updateDetailsforIDCard`,
  updateExpectedDate: `${Base_URI}/employee_asset_management/updateExpectedDate`,
  updateIssueDate: `${Base_URI}/employee_asset_management/updateIssueDate`,
  downloadReportForIDCard: `${Base_URI}/employee_asset_management/downloadReportForIDCard`,
  assignAssetToEmployee: `${Base_URI}/employee_asset_management/assignAssetToEmployee`,
  activeAssetFromInactive: `${Base_URI}/employee_asset_management/activeAssetFromInactive`,
  dataForAsset: `${Base_URI}/employee_asset_management/dataForAsset`,
  sendOtpForAsset: `${Base_URI}/employee_asset_management/sendOtpForAsset`,
  uploadFormImage: `${Base_URI}/employee_asset_management/uploadFormImage`,
  employeeUndertakingForm: `${Base_URI}/employee_asset_management/employeeUndertakingForm`,
  undertakingByEmployee: `${Base_URI}/employee_asset_management/undertakingByEmployee`,
  updateAssetDetails: `${Base_URI}/employee_asset_management/update-asset`,
  // ______________________________FMS________________________
  createBookingForRoom: `${Base_URI}/fms/createBookingForRoom`,
  getMeetingRoomStatusForScreen: `${Base_URI}/fms/getMeetingRoomStatusForScreen`,
  findRoomDetailsForScreen: `${Base_URI}/fms/findRoomDetailsForScreen`,
  getCreatedMeetingRecords: `${Base_URI}/fms/getCreatedMeetingRecords`,
  // ________________________________manager______________________
  showEmpSeparationRequest: `${Base_URI}/manager/showEmpSeparationRequest`,
  approveEmpSeparation: `${Base_URI}/manager/approveEmpSeparation`,
  rejectEmpSeparation: `${Base_URI}/manager/rejectEmpSeparation`,
  // ________________________________admin______________________
  getAllDepartmentList: `${Base_URI}/admin/getAllDepartmentList`,
  getAllDesignationList: `${Base_URI}/admin/all-designations`,
  getAllManagerListByDepartment: `${Base_URI}/admin/getAllManagerListByDepartment`,
  getDepartmentWiseRoles: `${Base_URI}/admin/getDepartmentWiseRoles`,
  getAllEmployeeList: `${Base_URI}/admin/getAllEmployeeList`,
  getEmployeeAccountList: `${Base_URI}/admin/getEmployeeAccountList`,
  createEmployeeByAdmin: `${Base_URI}/admin/createEmployeeByAdmin`,
  updateEmployeeByAdmin: `${Base_URI}/admin/updateEmployeeByAdmin`,
  getEmployeeAllDetails: `${Base_URI}/admin/getEmployeeAllDetails`,
  findTypeofLeaves: `${Base_URI}/admin/findTypeofLeaves`,
  getAllPendingEmployeeList: `${Base_URI}/admin/getAllPendingEmployeeList`,
  changeAccountStatus: `${Base_URI}/admin/changeAccountStatus`,
  allDocumentPendingEmployee: `${Base_URI}/admin/allDocumentPendingEmployee`,
  sendEmailPendingDocument: `${Base_URI}/admin/sendEmailPendingDocument`,
  generateExcelForPendingDocument: `${Base_URI}/admin/generateExcelForPendingDocument`,
  deleteEmployeeDocument: `${Base_URI}/admin/deleteEmployeeDocument`,
  getDeptRoleOnEmp: `${Base_URI}/admin/getDeptRoleOnEmp`,
  changeEmployeeDepartment: `${Base_URI}/admin/changeEmployeeDepartment`,
  changeEmployeeDesignation: `${Base_URI}/admin/changeEmployeeDesignation`,
  verifyReportingManager: `${Base_URI}/admin/verifyReportingManager`,
  changeReportingManager: `${Base_URI}/admin/changeReportingManager`,
  ReportingManagerDetails: `${Base_URI}/admin/ReportingManagerDetails`,
  showBGVDetails: `${Base_URI}/admin/showBGVDetails`,
  getAllWorkingFrom: `${Base_URI}/admin/getAllWorkingFrom`,
  HikvisionAttendancePopulation: `${Base_URI}/admin/HikvisionAttendancePopulation`,
  // getEmployeeAllPersonalDetails: `${Base_URI}/employee/getEmployeeAllPersonalDetails`,
  downloadRawDumpForMRF: `${Base_URI}/mrf/downloadRawDumpForMRF`,
  validateMRFForReAssign: `${Base_URI}/mrf/validateMRFForReAssign`,
  re_assign_mrf: `${Base_URI}/mrf/re_assign_mrf`,
  find_top_recruiter_for_mrf_dashboard: `${Base_URI}/mrf/find_top_recruiter_for_mrf_dashboard`,
  total_counts_for_mrf_tat: `${Base_URI}/mrf/total_counts_for_mrf_tat`,
  getAllCloseMrf: `${Base_URI}/mrf/getAllCloseMrf`,
  getProjectManger:`${Base_URI}/common_api/get-project-manger`,
  attritionDashBoardYTD:`${Base_URI}/common_api/attrition-dashBoard-YTD`,
  getAttritionData:`${Base_URI}/common_api/get-attrition-data`,
  getPmAttrition:`${Base_URI}/common_api/get-pm-attrition-data`,
  getManagerAttritionData:`${Base_URI}/common_api/get-manager-attrition-data`,
  getHodAttritionData:`${Base_URI}/common_api/get-hod-attrition-data`,
  getYtdAttritionData:`${Base_URI}/common_api/get-ytd-attrition-data`,
  //_____________________________common_api ___________________
  getAllEmployeeForReportingManager: `${Base_URI}/common_api/getAllEmployeeForReportingManager`,
  getDepartmentWiseDesignations: `${Base_URI}/common_api/getDepartmentWiseDesignations`,
  getReportingDesignations: `${Base_URI}/common_api/getReportingDesignations`,
  getDesignationWiseEmployee: `${Base_URI}/common_api/getDesignationWiseEmployee`,
  getHrmsStaticKeywordDetails: `${Base_URI}/common_api/getHrmsStaticKeywordDetails`,
  findEmployeeRatingSurvey: `${Base_URI}/common_api/findEmployeeRatingSurvey`,
  findEmployeeContribution: `${Base_URI}/common_api/findEmployeeContribution`,
  giveContribution: `${Base_URI}/common_api/giveContribution`,
  giveEmployeeRatingForSurvey: `${Base_URI}/common_api/giveEmployeeRatingForSurvey`,
  getTotalRatingSurveyResponseByEmployee: `${Base_URI}/common_api/getTotalRatingSurveyResponseByEmployee`,
  getFilteredEmployee: `${Base_URI}/common_api/getFilteredEmployee`,
  sendWelcomeMail: `${Base_URI}/common_api/sendWelcomeMail`,
  getTaskBox: `${Base_URI}/common_api/getTaskBox`,
  getKPIDashboard: `${Base_URI}/common_api/getKPIDashboard`,
  softwareListByDept: `${Base_URI}/common_api/softwareListByDept`,
  saveSoftwareDetails: `${Base_URI}/common_api/saveSoftwareDetails`,
  softwareList: `${Base_URI}/common_api/softwareList`,
  saveSoftwareDetailsInDept: `${Base_URI}/common_api/saveSoftwareDetailsInDept`,
  saveSoftwares: `${Base_URI}/common_api/saveSoftwares`,
  permissions: `${Base_URI}/common_api/permissions`,
  roleWisepermissions: `${Base_URI}/common_api/roleWisepermissions`,
  updatePermissions: `${Base_URI}/common_api/updatePermissions`,
  getStates: `${Base_URI}/common_api/getStates`,
  getBranches: `${Base_URI}/common_api/getBranches`,
  getDropdownData: `${Base_URI}/common_api/dropdown-master-data`,
  getOverAllPerformance: `${Base_URI}/common_api/getOverAllPerformance`,
  getDashboardHOD: `${Base_URI}/common_api/getDashboardHOD`,
  getManagerDashboard: `${Base_URI}/common_api/getManagerDashboard`,
  managerDashboardSidebar: `${Base_URI}/common_api/DashboardSidebar`,
  DashboardSidebarTL: `${Base_URI}/common_api/DashboardSidebarTL`,
  getEmployeeDashboard: `${Base_URI}/common_api/getEmployeeDashboard`,

  // Rating FeedBack DashBoard API's
  findEmpMoodRating: `${Base_URI}/employee_mood_survey/findEmpMoodRating`,
  getDataForFeedbackEmpSection: `${Base_URI}/employee_mood_survey/getDataForFeedbackEmpSection`,
  findKeyEmployeeFrust: `${Base_URI}/employee_mood_survey/findKeyEmployeeFrust`,
  findKeyDepartmentFrust: `${Base_URI}/employee_mood_survey/findKeyDepartmentFrust`,
  weeklyESATCount: `${Base_URI}/employee_mood_survey/weeklyESATCount`,
  downloadRawDumpForDailyRatingSurvey: `${Base_URI}/admin/downloadRawDumpForDailyRatingSurvey`,
  moodMeter: `${Base_URI}/employee_mood_survey/moodMeter`,
  getReportKPIDashboard: `${Base_URI}/common_api/getReportKPIDashboard`,
  //_____________________________pms_api ___________________
  createKRA: `${Base_URI}/pms/createKRA`,
  getCreatedKRA: `${Base_URI}/pms/getCreatedKRA`,
  createKPI: `${Base_URI}/pms/createKPI`,
  getAllKPIByKRAId: `${Base_URI}/pms/getAllKPIByKRAId`,
  KPIDelete: `${Base_URI}/pms/KPIDelete`,
  KPIUpdate: `${Base_URI}/pms/KPIUpdate`,
  updateKPITarget: `${Base_URI}/pms/updateKPITarget`,
  getAllDepartment: `${Base_URI}/pms/getAllDepartment`,
  createTemplateParameter: `${Base_URI}/pms/createTemplateParameter`,
  assignKRA: `${Base_URI}/pms/assignKRA`,
  getMyKPI: `${Base_URI}/pms/getMyKPI`,
  findAssignedKRAForEmployee: `${Base_URI}/pms/findAssignedKRAForEmployee`,
  getKpiByKraId: `${Base_URI}/pms/getKpiByKraId`,
  setKraRatingTypeForDepartment: `${Base_URI}/pms/setKraRatingTypeForDepartment`,
  removeKraRatingTypeForDepartment: `${Base_URI}/pms/removeKraRatingTypeForDepartment`,
  getKraRatingTypeForDepartment: `${Base_URI}/pms/getKraRatingTypeForDepartment`,
  setUpFinancialYear: `${Base_URI}/pms/setUpFinancialYear`,
  getOrganisationFinancialYearSetup: `${Base_URI}/pms/getOrganisationFinancialYearSetup`,
  getEmployeeRatingForCurrnetMonth: `${Base_URI}/pms/getEmployeeRatingForCurrnetMonth`,
  giveRatingForCurrentMonth: `${Base_URI}/pms/giveRatingForCurrentMonth`,
  createParameter: `${Base_URI}/pms/createParameter`,
  getCreatedParameter: `${Base_URI}/pms/getCreatedParameter`,
  getTemplateForRating: `${Base_URI}/pms/getTemplateForRating`,
  deleteParameter: `${Base_URI}/pms/deleteParameter`,
  getKRAParameterDetails: `${Base_URI}/pms/getKRAParameterDetails`,
  getAllEmployeeForReportingManagerPms: `${Base_URI}/pms/getAllEmployeeForReportingManagerPms`,
  getEmployeeRatingFullDetails: `${Base_URI}/pms/getEmployeeRatingFullDetails`,
  generateExcelForKRAAssigned: `${Base_URI}/pms/generateExcelForKRAAssigned`,
  downloadKRARawDump: `${Base_URI}/pms/downloadKRARawDump`,
  generateAverageYearlyRating: `${Base_URI}/pms/generateAverageYearlyRating`,
  pmsRatingYesNo: `${Base_URI}/pms/pmsRatingYesNo`,
  getFinancialYear: `${Base_URI}/pms/getFinancialYear`,
  bellCurvePMS: `${Base_URI}/pms/allEmployeesRating`,
  getDistinctKRAparameters: `${Base_URI}/pms/getDistinctKRAparameters`,
  getDistinctKPIparameters: `${Base_URI}/pms/getDistinctKPIparameters`,
  uploadExcel_KRA_RATING: `${Base_URI}/pms/uploadExcel_KRA_RATING`,
  insert_acknowledgement: `${Base_URI}/pms/insert_acknowledgement`,
  getHODList: `${Base_URI}/pms/getHOD`,
  findAllEmployeeRatingWise: `${Base_URI}/pms/findAllEmployeeRatingWise`,
  addKRAbyUser: `${Base_URI}/pms/addKRAbyUser`,
  feedbackAcknowledgementReport: `${Base_URI}/pms/feedbackAcknowledgementReport`,
  getPMSTypes: `${Base_URI}/pms/get-types`,
  generateWarningLetter: `${Base_URI}/pms/generate-warning-letter`,
  getWarningLetterTemplateByFilter: `${Base_URI}/pms//get-warning-template-by-filter`,
  saveWarningLetter: `${Base_URI}/pms/save-warning-letters`,
  getRatingDurations: `${Base_URI}/pms/get-rating-durations`,
  updateRatingDurationByDesignation: `${Base_URI}/pms/update-rating-duration-by-designation`,
  updateRatingDurationByEmployee: `${Base_URI}/pms/update-rating-duration-by-employee`,
  getTeamForFeedback: `${Base_URI}/pms/get-team-for-feedback`,
  getCurrentFinancialYearKRA: `${Base_URI}/pms/get-current-financial-year-kra`,
  savePMSFeedback: `${Base_URI}/pms/save-feedback`,
  saveFeedbackTeam: `${Base_URI}/pms/save-feedback-team`,
  getReceivedFeedbackOnFilter: `${Base_URI}/pms/get-received-feedback-on-filter`,
  getGivenFeedback: `${Base_URI}/pms/get-given-feedback`,
  getFeedbackDashboard: `${Base_URI}/pms/get-feedback-dashboard-data`,
  getEmployeeFeedbacks: `${Base_URI}/pms/get-employee-feedbacks`,
  getEmployeeAllFeedbacks: `${Base_URI}/pms/get-employee-all-feedbacks`,
  getAllGivenRating: `${Base_URI}/pms/get-given-rating`,
  getSelfRatingPermissions: `${Base_URI}/pms/get-self-rating-permissions`,
  updateSelfRatingPermissions: `${Base_URI}/pms/update-self-rating-permissions`,
  // ____________________________Ticket Support API____________________________________
  get_all_ticket_issue_names: `${Base_URI}/support_ticket/get_all_ticket_issue_names`,
  generateExcelforTicketManagementSystem: `${Base_URI}/support_ticket/generateExcelforTicketManagementSystem`,
  issueTicket: `${Base_URI}/support_ticket/issueTicket`,
  ticketDetails: `${Base_URI}/support_ticket/ticketDetails`,
  all_help_topic: `${Base_URI}/support_ticket/all_help_topic`,
  all_HRMS_status_details: `${Base_URI}/support_ticket/all_HRMS_status_details`,
  showAllTickets: `${Base_URI}/support_ticket/showAllTickets`,
  assignTicket: `${Base_URI}/support_ticket/assignTicket`,
  showAssignedSupportTicket: `${Base_URI}/support_ticket/showAssignedSupportTicket`,
  closeTicket: `${Base_URI}/support_ticket/closeTicket`,
  // get_all_ticket_issue_names: `${Base_URI}/support_ticket/get_all_ticket_issue_names`,
  get_all_ticket_issue_subtype_names: `${Base_URI}/support_ticket/get_all_ticket_issue_subtype_names`,
  get_all_resolution_type: `${Base_URI}/support_ticket/get_all_resolution_type`,
  get_all_resolution_subtype: `${Base_URI}/support_ticket/get_all_resolution_subtype`,
  ticketReopen: `${Base_URI}/support_ticket/ticketReopen`,
  getAllRejectMrfByHR: `${Base_URI}/mrf/getAllRejectMrfByHR`,
  holdMRF: `${Base_URI}/mrf/holdMRF`,
  editTicketDetails: `${Base_URI}/support_ticket/editTicketDetails`,
  watchersDetails: `${Base_URI}/support_ticket/watchersDetails`,
  CSAT: `${Base_URI}/support_ticket/csat_count_for_ticket_management_system`,
  it_support_application_closed_type_details: `${Base_URI}/support_ticket/it_support_application_closed_type_details`,
  companyTools: `${Base_URI}/support_ticket/companyTools`,
  getallemployees: `${Base_URI}/support_ticket/getallemployees`,
  reassignTicket: `${Base_URI}/support_ticket/reassignTicket`,
  addItTicket: `${Base_URI}/support_ticket/add-it-ticket`,
  getProcessList: `${Base_URI}/support_ticket/getProcessList`,
  itAssignedTask: `${Base_URI}/support_ticket/it-assigned-task`,
  closeItTicket: `${Base_URI}/support_ticket/close-it-ticket`,
  itTrackerReport: `${Base_URI}/support_ticket/it-tracker-report`,
  getTicketComments: `${Base_URI}/support_ticket/get-ticket-comment`,
  addTicketComments: `${Base_URI}/support_ticket/add-ticket-comment`,

  //______________________________PAy Roll________________________________________
  updateCalculatedCtc: `${Base_URI}/admin/calculate_ctc`,
  updateCalculatedCtc1: `${Base_URI}/admin/calculate_ctc1`,
  addEmployeeOfferLetter: `${Base_URI}/admin/addEmployeeOfferLetter`,
  salaryComponents: `${Base_URI}/admin/salaryComponents`,
  offerLetterDropdown: `${Base_URI}/admin/offerLetterDropdown`,
  generateOfferLetter: `${Base_URI}/admin/generateOfferLetter`,
  generateOfferLetter1: `${Base_URI}/admin/generateOfferLetter1`,
  getBOEmployeeAttendanceDetails: `${Base_URI}/admin/getBOEmployeeAttendanceDetails`,
  getSalaryComponents: `${Base_URI}/admin/getSalaryComponents`,
  getEarningType: `${Base_URI}/admin/getEarningType`,
  addSalaryComponentForCompany: `${Base_URI}/admin/addSalaryComponentForCompany`,
  addSalaryComponent: `${Base_URI}/admin/addSalaryComponent`,
  applyTourApplication: `${Base_URI}/attendance_management/applyTourApplication `,
  employeeAttendanceCorrectionApply: `${Base_URI}/attendance_management/employeeAttendanceCorrectionApply`,
  addEarningType: `${Base_URI}/admin/addEarningType`,
  getEarningTypeById: `${Base_URI}/admin/getEarningTypeById`, //get
  getSalarycomponent: `${Base_URI}/admin/getSalarycomponent`, //get
  addEarningComponent: `${Base_URI}/admin/addEarningComponent`,
  activeSalaryComponent: `${Base_URI}/admin/activeSalaryComponent`,
  getEpf: `${Base_URI}/admin/getEpf`,
  addEpf: `${Base_URI}/admin/addEpf`,
  editEpf: `${Base_URI}/admin/editEpf`,
  addSalaryTemplate: `${Base_URI}/admin/addSalaryTemplate`,
  getSalaryTemplate: `${Base_URI}/admin/getSalaryTemplate`,
  getSalaryTemplateById: `${Base_URI}/admin/getSalaryTemplateById`,
  getAllTemplate: `${Base_URI}/admin/getAllTemplate`,
  addInvestmentCategory: `${Base_URI}/admin/addInvestmentCategory`,
  addsubcategory: `${Base_URI}/admin/addsubcategory`,
  investment: `${Base_URI}/admin/investment`,
  Calculate: `${Base_URI}/admin/Calculate`,
  addemployees: `${Base_URI}/admin/addemployees`,
  departments: `${Base_URI}/admin/departments`,
  salarydetails: `${Base_URI}/admin/salarydetails`,
  editpage: `${Base_URI}/admin/editpage`,
  GetEmployees: `${Base_URI}/admin/GetEmployees`,
  editSubCategory: `${Base_URI}/admin/editSubCategory`,
  deleteSubcategory: `${Base_URI}/admin/deleteSubcategory`,
  calculateHra: `${Base_URI}/admin/calculateHra`,
  personaldetails: `${Base_URI}/admin/personaldetails`,
  paymentdetails: `${Base_URI}/admin/paymentdetails`,
  addAllInvestment: `${Base_URI}/admin/addAllInvestment`,
  payrun: `${Base_URI}/admin/payrun`,
  getEmployeeSalaryDetails: `${Base_URI}/admin/getEmployeeSalaryDetails`,
  notifications: `${Base_URI}/admin/notifications`,
  addPaySchedule: `${Base_URI}/admin/addPaySchedule`,
  payschedule: `${Base_URI}/admin/payschedule`,
  createPayrun: `${Base_URI}/admin/createPayrun`,
  payrunActive: `${Base_URI}/admin/payrunActive`,
  payrunDelete: `${Base_URI}/admin/payrunDelete`,
  viewMonthlySalary: `${Base_URI}/admin/viewMonthlySalary`,
  addPayrollEarning: `${Base_URI}/admin/addPayrollEarning`,
  getpayrollEarning: `${Base_URI}/admin/getpayrollEarning`,
  getPayrollDeductions: `${Base_URI}/admin/getPayrollDeductions`,
  editPayrollDeductions: `${Base_URI}/admin/editPayrollDeductions`,
  shareSalarySlip: `${Base_URI}/admin/shareSalarySlip`,
  generateViewSalarySheet: `${Base_URI}/admin/generateViewSalarySheet`,
  updateEmployeeSalary: `${Base_URI}/admin/updateEmployeeSalary`,
  payrollHistory: `${Base_URI}/admin/payrollHistory`,
  payrollDashboardData: `${Base_URI}/admin/payrollDashboardData`,
  updateItDeclaration: `${Base_URI}/admin/updateItDeclaration`,
  downloadPayslipRequest: `${Base_URI}/admin/downloadPayslipRequest`,
  approvedDownloadPayslipRequest: `${Base_URI}/admin/approvedDownloadPayslipRequest`,
  existingSalaryReport: `${Base_URI}/admin/existing-salary-report`,
  downloadSampleForArrearBulkUpload: `${Base_URI}/admin/download-sample-for-arrear-bulk-upload`,
  bulkUploadEmployeeArrear: `${Base_URI}/admin/bulk-upload-employee-arrear`,
  saveLeaveConfigurations: `${Base_URI}/admin/save-leave-configuration`,
  getDepartmentForLeaveConfiguration: `${Base_URI}/admin/get-leave-configuration-departments`,

  // ------appraisal-----
  addAppraisal: `${Base_URI}/admin/add-appraisal`,
  getDesignationForDepartments: `${Base_URI}/admin/get-designation-for-multiple-department`,
  updateAppraisal: `${Base_URI}/admin/update-appraisal`,
  getDesignationForAppraisal: `${Base_URI}/admin/designation-for-appraisal`,
  getEmployeeForDesignation: `${Base_URI}/admin/get-employee-for-multiple-designation`,
  addSalaryBracket: `${Base_URI}/admin/add-salary-bracket`,
  updateSalaryBracket: `${Base_URI}/admin/update-salary-brackets`,
  updateDropdownRules: `${Base_URI}/admin/update-dropdown-rules`,
  getAppraisalList: `${Base_URI}/admin/appraisal-list`,
  employeeForAppraisal: `${Base_URI}/admin/employee-data-for-appraisal`,
  addAppraisalExceptions: `${Base_URI}/admin/add-appraisal-exceptions`,
  approveRejectAppraisal: `${Base_URI}/admin/approve-reject-appraisal`,
  sendAppraisalLetter: `${Base_URI}/admin/send-appraisal-letter`,
  getSalaryBracket: `${Base_URI}/admin/salary-bracket-list`,

  //____________________________Probation Module API____________________________________
  getEmployeeAllPersonalDetails: `${Base_URI}/employee/getEmployeeAllPersonalDetails`,
  fillEmpProbationReviewDetails: `${Base_URI}/pms/fillEmpProbationReviewDetails`,
  probationReview: `${Base_URI}/pms/probationReview`,
  getprobationDetails: `${Base_URI}/pms/getprobationDetails`,
  extendProbatationFormFill: `${Base_URI}/pms/extendProbatationFormFill`,
  probationExtendedMail: `${Base_URI}/pms/probationExtendedMail`,
  //____________________________Attandence management API____________________________________
  getHolidayList: `${Base_URI}/attendance_management/holidays`,
  addHolidays: `${Base_URI}/attendance_management/add_holidays`,
  updateHolidays: `${Base_URI}/attendance_management/update_holidays`,
  delete_holidays: `${Base_URI}/attendance_management/delete_holidays`,
  attendanceCheckInCheckOut: `${Base_URI}/attendance_management/attendanceCheckInCheckOut`,
  get_employee_shift: `${Base_URI}/attendance_management/get_employee_shift`,
  create_shift: `${Base_URI}/attendance_management/create_shift`,
  UpdateEmployeeShift: `${Base_URI}/attendance_management/UpdateEmployeeShift`,
  deleteEmployeeShift: `${Base_URI}/attendance_management/deleteEmployeeShift`,
  get_attendance: `${Base_URI}/attendance_management/get_attendance`,
  applyLeaveApplication: `${Base_URI}/attendance_management/applyLeaveApplication`,
  attendanceBasicDetails: `${Base_URI}/attendance_management/attendanceBasicDetails`,
  getAttendanceLogsWithDuration: `${Base_URI}/attendance_management/getAttendanceLogsWithDuration`,
  attendance_shift_management: `${Base_URI}/attendance_management/attendance_shift_management`,
  get_attendance_shift_management: `${Base_URI}/attendance_management/get_attendance_shift_management`,
  assignAttendanceShift: `${Base_URI}/attendance_management/assignAttendanceShift`,
  assignAttendanceOtherShift: `${Base_URI}/attendance_management/assignAttendanceOtherShift`,
  getEmployeebyShift: `${Base_URI}/attendance_management/getEmployeebyShift`,
  // get_employee_leave_name: `${Base_URI}/attendance_management/get_employee_leave_name`,
  // findAttendanceSummary: `${Base_URI}/attendance_management/findAttendanceSummary`,
  getAttendanceSummaryEmp: `${Base_URI}/attendance_management/getAttendanceSummaryEmp`,
  getAttendanceStatus: `${Base_URI}/attendance_management/get_attendance_status`,
  getAttendanceStatusAdmin: `${Base_URI}/attendance_management/get_attendance_status_admin`,
  getShiftByUser: `${Base_URI}/attendance_management/getShiftByUser`,
  deleteShiftByUser: `${Base_URI}/attendance_management/deleteShiftByUser`,
  addRoster: `${Base_URI}/attendance_management/addRoster`,
  getroster: `${Base_URI}/attendance_management/getroster`,
  getRosterByUser: `${Base_URI}/attendance_management/getRosterByUser`,
  getEmployeeBydepartment: `${Base_URI}/attendance_management/getEmployeeBydepartment`,
  deleteRosterEmployee: `${Base_URI}/attendance_management/deleteRosterEmployee`,
  deleteRoster: `${Base_URI}/attendance_management/deleteRoster`,
  updateRoster: `${Base_URI}/attendance_management/updateRoster`,
  applyCompoffLeaveApplication: `${Base_URI}/attendance_management/applyCompoffLeaveApplication`,
  employeeAttendanceCorrection: `${Base_URI}/attendance_management/employeeAttendanceCorrection`,
  getEmpAppliedApplication: `${Base_URI}/attendance_management/getEmpAppliedApplication`,
  approveRejectAttendance: `${Base_URI}/attendance_management/approveRejectAttendance`,
  getattendanceSummary: `${Base_URI}/attendance_management/getattendanceSummary`,
  get_employee_leave_name: `${Base_URI}/attendance_management/get_employee_leave_name`,
  getTotalLeaveBalanceForEmp: `${Base_URI}/attendance_management/getTotalLeaveBalanceForEmp`,
  getShiftByUserInMonth:`${Base_URI}/attendance_management/getShiftByUserInMonth`,
  getAllLeaveType: `${Base_URI}/attendance_management/getAllLeaveType`,
  get_calculated_Attendance: `${Base_URI}/admin/get_calculated_Attendance`,
  remove_applied_leave: `${Base_URI}/attendance_management/remove_applied_leave`,
  remove_applied_tour: `${Base_URI}/attendance_management/remove_applied_tour`,
  creditLeaveByAdmin: `${Base_URI}/attendance_management/creditLeaveByAdmin`,
  lockAttendance: `${Base_URI}/attendance_management/lockAttendance`,
  getLockAttendanceData: `${Base_URI}/attendance_management/getLockAttendanceData`,
  getLeaveSummaryReport: `${Base_URI}/admin/leave-summary-report`,
  getentities: `${Base_URI}/attendance_management/getentities`,
  getAttendanceLocations: `${Base_URI}/attendance_management/getAttendanceLocations`,
  addAttendanceLocation: `${Base_URI}/attendance_management/addAttendanceLocation`,
  updateAttendanceLocation: `${Base_URI}/attendance_management/updateAttendanceLocation`,
  deleteAttendanceLocation: `${Base_URI}/attendance_management/deleteAttendanceLocation`,
  getAttendanceDurationByLogs: `${Base_URI}/attendance_management/getAttendanceDurationByLogs`,
  getCorrectionCounter: `${Base_URI}/attendance_management/correction-counter`,
  getUserByShift: `${Base_URI}/attendance_management/getUserByShift`,
  empLeaveshiftwise:`${Base_URI}/attendance_management/empLeaveshiftwise`,
  assignOtherShift:`${Base_URI}/attendance_management/assignOtherShift`,
  setRosterWeekOff:`${Base_URI}/attendance_management/setRoster-weekoff`,
  getRosterWeekOff:`${Base_URI}/attendance_management/get-roster-weekoffs`,
  deleteUserByShift:`${Base_URI}/attendance_management/deleteUserByShift`,
  ActiveORInactiveShift:`${Base_URI}/attendance_management/Active-inactive-Shift`,
  userShiftDeatil:`${Base_URI}/attendance_management/user-shift-deatil`,
  getWeekOffByEmp:`${Base_URI}/attendance_management/get-Weekoff-byemp`,

  // __________________Travel and Claim_____________________________
  applyForTravel: `${Base_URI}/travel/applyForTravel`,
  getAppliedTravelDetails: `${Base_URI}/travel/getAppliedTravelDetails`,
  approveRejectTraveRequest: `${Base_URI}/travel/approveRejectTraveRequest`,
  getTraveRequestAfterApproval: `${Base_URI}/travel/getTraveRequestAfterApproval`,
  getTravelHistory: `${Base_URI}/travel/getTravelHistory`,
  uploadDocumentForClaim: `${Base_URI}/travel/uploadDocumentForClaim`,
  getUploadedDocForTravelClaim: `${Base_URI}/travel/getUploadedDocForTravelClaim`,

  //_______________________Onboard Survey________________________
  getSurveyDetails: `${Base_URI}/common_api/getSurveyDetails`,
  addSurveyName: `${Base_URI}/common_api/addSurveyName`,
  getAllSurveyName: `${Base_URI}/common_api/getAllSurveyName`,
  addSurveyDetails: `${Base_URI}/common_api/addSurveyDetails`,
  addSurveyResponse: `${Base_URI}/common_api/addSurveyResponse`,
  getSurveyStatus: `${Base_URI}/common_api/getSurveyStatus`,
  DeleteSurveyName: `${Base_URI}/common_api/DeleteSurveyName`,
  DeleteSurveyQuestion: `${Base_URI}/common_api/DeleteSurveyQuestion`,
  DeleteSurveyOption: `${Base_URI}/common_api/DeleteSurveyOption`,
  UpdateSurveyQuestion: `${Base_URI}/common_api/UpdateSurveyQuestion`,
  UpdateSurveyOption: `${Base_URI}/common_api/UpdateSurveyOption`,
  getAllSurveyResponses: `${Base_URI}/common_api/getAllSurveyResponses`,
  getEachEmpoyeeSurveyResponses: `${Base_URI}/common_api/getEachEmpoyeeSurveyResponses`,
  addTopicIssueTypeSubtype: `${Base_URI}/support_ticket/addTopicIssueTypeSubtype`,
  deleteTopicIssueTypeSubtype: `${Base_URI}/support_ticket/deleteTopicIssueTypeSubtype`,
  ticketHelpIssueDetails: `${Base_URI}/support_ticket/ticketHelpIssueDetails`,
  ticketUpdate: `${Base_URI}/support_ticket/ticketUpdate`,
  getTicketHistory: `${Base_URI}/support_ticket/getTicketHistory`,
  updateTime: `${Base_URI}/support_ticket/updateTime`,
  //whatsapp api integration
  whatsAppApiSendMessage: 'https://betablaster.in/api/send.php',
  whatsAppApiSendMessage2: 'https://conversations.messagebird.com/v1/send',
  whatsAppApiSendMessage2Template: `${Base_URI}/whatsapp/sendWhatsappTemplate`,
  clientdemo: 'https://clientdemo.qdegrees.com/api/get-url',
  //Separation  api Integration
  getAllEmpId: `${Base_URI}/employee_separation/getAllEmpId`,
  basicDetailForEmpSeparation: `${Base_URI}/employee_separation/basicDetailForEmpSeparation`,
  applyForSaperation_HR: `${Base_URI}/employee_separation/applyForSaperation_HR`,
  deleteEmployeeProfilePic: `${Base_URI}/employee/deleteEmployeeProfilePic`,
  getDataforFeedbackForm: `${Base_URI}/employee_separation/view_employee_exit_interview_feedback_form`,
  fillEmployeeExitFeedbackForm: `${Base_URI}/employee_separation/insert_employee_exit_interview_feedback_form`,
  employee_exit_interview_feedback_form: `${Base_URI}/employee_separation/employee_exit_interview_feedback_form`,
  fullNFinalRequestClose: `${Base_URI}/employee_separation/fullNFinalRequestClose`,
  // shrinkage
  getShrinkage: `${Base_URI}/attendance_management/getShrinkage`,
  updateAttendanceStatus: `${Base_URI}/attendance_management/updateAttendanceStatus`,

  // notice period
  verifyNoticePeriod: `${Base_URI}/admin/verifyNoticePeriod_byEmp_id`,
  ChangeNoticePeriod: `${Base_URI}/admin/changeNoticePeriod`,
  attendanceSalaryCalculation: `${Base_URI}/admin/attendanceSalaryCalculation`,
  generateArrearCalculation: `${Base_URI}/admin/generateArrearCalculation`,
  addArrear: `${Base_URI}/admin/addArrear`,
  bulkUploadEmployeeSalary: `${Base_URI}/admin/bulkUploadEmployeeSalary`,
  downloadSampleForSalaryBulkUpload: `${Base_URI}/admin/downloadSampleForSalaryBulkUpload`,
  saveSalarySlipNotification: `${Base_URI}/admin/saveSalarySlipNotification`,
  calculateTax: `${Base_URI}/admin/calculateTax`,
  getDeductionsFromMasters: `${Base_URI}/admin/getDeductionsFromMasters`,
  pfMonthlySummary: `${Base_URI}/admin/pfMonthlySummary`,
  esiMonthlySummary: `${Base_URI}/admin/esiMonthlySummary`,
  salaryComplianceReport: `${Base_URI}/admin/salaryComplianceReport`,
  joinersLeaversSalaryReport: `${Base_URI}/admin/joiners-leavers-report`,
  combinedEChallan: `${Base_URI}/admin/combined-e-challan`,
  //ats
  intiateCall: `${Base_URI}/ats/get-s2call-access-num`,
  getCandidates: `${Base_URI}/ats/get-candidates`,
  getCallHistoryByCandidate: `${Base_URI}/ats/get-call-history-by-candidate`,
  getCallFeedbackEvents: `${Base_URI}/ats/get-call-feedback-events`,
  saveFeedback: `${Base_URI}/ats/saveFeedback`,
  recruiterList: `${Base_URI}/ats/recruiterList`,
  generateRecruiterCountLog: `${Base_URI}/ats/generateRecruiterCountLog`,

  //chat application url
  fileMsg: `${Base_URI_Chat}/fileMsg`,
  chat: `${Base_URI_Chat}/chat`,
  createGroup: `${Base_URI_Chat}/createGroup`,
  userRoomDetails: `${Base_URI_Chat}/userRoomDetails`,
  unReadMessage: `${Base_URI_Chat}/unReadMessage`,
  fetchGroupDetails: `${Base_URI_Chat}/fetchGroupDetails`,
 // message service
  addUserGroup: `${Base_URI_Chat}/message/add-user-group`,
  deleteUserGroup: `${Base_URI_Chat}/message/delete-user-group`,
  getUserGroups: `${Base_URI_Chat}/message/get-user-groups`,
  addUserConnection: `${Base_URI_Chat}/message/add-user-connection`,
  deleteUserConnection: `${Base_URI_Chat}/message/delete-user-connection`,
  getUserConnections: `${Base_URI_Chat}/message/get-user-connections`,
  getMessages: `${Base_URI_Chat}/message/get-messages`,
  sendMessage: `${Base_URI_Chat}/message/send-message`,
  deleteMessage: `${Base_URI_Chat}/message/delete-message`,
  getUnreadMessageCounts: `${Base_URI_Chat}/message/get-unread-message-counts`,
  setreadMessageCounts: `${Base_URI_Chat}/message/set-read-message-counts`,
  addAttachments: `${Base_URI_Chat}/message/add-attachment`,

  // rest api
  activeEmployee: `${Base_URI}/rest_api/activeEmployee`,
  guestDetails: `${Base_URI}/rest_api/guestDetails`,
  sendOTP: `${Base_URI}/rest_api/sendOtp`,
  verifyOTP: `${Base_URI}/rest_api/verifyOtp`,
  verifiedUser: `${Base_URI}/rest_api/verifiedUser`,
  allguestDetails: `${Base_URI}/rest_api/allguestDetails`,
  getDataByEmpID: `${Base_URI}/rest_api/getDataByEmpID`,
  empTempDetails: `${Base_URI}/rest_api/empTempDetails`,
  allEmployeeDetails: `${Base_URI}/rest_api/allEmployeeDetails`,
  updateTempCard: `${Base_URI}/rest_api/updateTempCard`,
  getEmpIdByCompany: `${Base_URI}/rest_api/emp-id-by-company`,
  selectedCandidateInIJP: `${Base_URI}/rest_api/selected-candidateIJP`,
  getAllSelectedCandidate: `${Base_URI}/rest_api/get-all-selected-IJP`,
  updateIJPData: `${Base_URI}/rest_api/update-details`,
  IJPAnnouncement: `${Base_URI}/rest_api/send-IJP-Announcement`,
  getAllDataForPermission: `${Base_URI}/rest_api/get-all-app-permission`,
  updateAppPermissions: `${Base_URI}/rest_api/update-app-permission`,
  update2FAPermissions: `${Base_URI}/rest_api/update-2fa-permission`,
  correctionNotification: `${Base_URI}/rest_api/get-correction-notification`,
  getClients: `${Base_URI}/rest_api/get-client-info`,
  getLeaveLedgerForEmployee: `${Base_URI}/rest_api/get-leave-ledger`,
  getCTCRange: `${Base_URI}/rest_api/get-ctc-details`,
  sendMailForApproval: `${Base_URI}/rest_api/send-mail-for-approval`,
  approveRejectCtcDetails: `${Base_URI}/rest_api/approve-reject-ctc-details`,
  getLeaveLedgerForEmployee: `${Base_URI}/rest_api/get-leave-ledger`,
  getDepartmentWiseBudgetReport: `${Base_URI}/rest_api/department-wise-budget`,
  getBudgetApprovalReport: `${Base_URI}/rest_api/department-wise-approval-report`,
  getDesignationWiseBudgetReport: `${Base_URI}/rest_api/designation-wise-budget`,
  getFreezeLeaveReport: `${Base_URI}/rest_api/freeze-leave-report`,  
  leaveBalanceReport: `${Base_URI}/rest_api/leave-balance-report`,  

  //policies API
  savePolicyCategory: `${Base_URI}/policies/save-policy-category`,
  policyCategory: `${Base_URI}/policies/policy-category`,
  updatePolicyCategory: `${Base_URI}/policies/update-policy-category`,
  savePolicies: `${Base_URI}/policies/save-policies`,
  getPolicies: `${Base_URI}/policies/get-policies`,
  updatePolicy: `${Base_URI}/policies/update-policy`,
  deletePolicyCategory: `${Base_URI}/policies/delete-policy-category`,
  deletePolicy: `${Base_URI}/policies/delete-policy`,

   //task management API
   saveTasks: `${Base_URI}/tasks/save-tasks`,
   getCreatedTask: `${Base_URI}/tasks/get-tasks`,
   deleteTask: `${Base_URI}/tasks/delete-task`,
   updateTask: `${Base_URI}/tasks/update-task`,
   getSubtaskFromId: `${Base_URI}/tasks/get-subtask-from-task`,
   getProjectCategory: `${Base_URI}/tasks/get-project-category`,
   saveProjects: `${Base_URI}/tasks/save-projects`,
   updateProject: `${Base_URI}/tasks/update-Project`,
   deleteProject: `${Base_URI}/tasks/delete-Project`,
   getProjects: `${Base_URI}/tasks/get-projects`,
   saveProjectCategory: `${Base_URI}/tasks/save-project-category`,
   deleteProjectCategory: `${Base_URI}/tasks/delete-Project-Category`,
   updateProjectCategory: `${Base_URI}/tasks/update-Project-Category`,
   saveTimeTracking: `${Base_URI}/tasks/save-time-tracking`,
   getTaskHistoryByType: `${Base_URI}/tasks/task-history-by-type`,
   deleteTaskHistory: `${Base_URI}/tasks/delete-task-history`,
   updateTaskHistory: `${Base_URI}/tasks/update-task-history`,
   getAssigneeList: `${Base_URI}/tasks/getAssigneeList`,
   addTaskfile:  `${Base_URI}/tasks/add-tasks-files`,
   deleteTaskFile:`${Base_URI}/tasks/deleteTaskFile`,

  /****Reimbursement API****/
  reimbursementType: `${Base_URI}/reimbursement/reimbursement-type`,
  updateReimbursementType: `${Base_URI}/reimbursement/update-reimbursement-type`,
  addReimbursementType: `${Base_URI}/reimbursement/add-reimbursement-type`,
  deleteReimbursementType: `${Base_URI}/reimbursement/delete-reimbursement-type`,
  reimbursementAmount: `${Base_URI}/reimbursement/reimbursement-amount`,
  addReimbursement: `${Base_URI}/reimbursement/add-reimbursement`,
  addReimbursementRules: `${Base_URI}/reimbursement/add-reimbursement-rules`,
  reimbursementRules: `${Base_URI}/reimbursement/get-reimbursement-rules`,
  addReimbursmentRuleID: `${Base_URI}/reimbursement/add-reimbursement-id`,
  updateReimbursementRules: `${Base_URI}/reimbursement/update-reimbursement-rule`,
  deleteReimbursementRules: `${Base_URI}/reimbursement/delete-reimbursement-rule`,
  getRulesByID: `${Base_URI}/reimbursement/reimbursement-rules-byID`,
  getAllReimbursementData: `${Base_URI}/reimbursement/get-reimbursement-data`,
  getApprovalList: `${Base_URI}/reimbursement/reimbursement-approval-list`,
  approveRejectManager: `${Base_URI}/reimbursement/approve-reject-manager`,
  approveRejectHOD: `${Base_URI}/reimbursement/approve-reject-HOD`,
  deleteReimbursement: `${Base_URI}/reimbursement/delete-reimbursement`,
  getAllReimbursementBillByID: `${Base_URI}/reimbursement/get-image-reimbursement`,
  getInterviewRoundAssesment: `${Base_URI}/employee/getInterviewRoundAssesment`,
  settingsbyadmin: `${Base_URI}/rest_api/settings-by-client`,
  updatesettings: `${Base_URI}/rest_api/update-settings`,
  // https://cbt.glueple.com:3021/rest_api/settings-by-admin
  getEmployeeOffs: `${Base_URI}/attendance_management/getEmployeeOffs`,
  getShiftdetailsbyUser: `${Base_URI}/attendance_management/getShiftdetailsbyUser`,
  updateDepartmentDetails: `${Base_URI}/admin/update-department-details`,

  //resume parsing api
  resumeParser: `${Base_URI}/hrRecruiter/resume-parser`,
  entitiesData: `${Base_URI}/employee/entitiesData`,

  // ----------------------------------------Software Management Apis-------------------------------------------------
  addSoftwareModelAsset: `${Base_URI}/softwareAssest/addSoftwareModelAsset`,
  getSoftwareModelAsset: `${Base_URI}/softwareAssest/getSoftwareModelAsset`,
  addSoftwareAddentitlement: `${Base_URI}/softwareAssest/addSoftwareAddentitlement`,
  getSoftwareAddentitlement: `${Base_URI}/softwareAssest/getSoftwareAddentitlement`,
  getSoftwarePendingRequest: `${Base_URI}/softwareAssest/getSoftwarePendingRequest`,
  getAddentitlementOnModelAsset: `${Base_URI}/softwareAssest/getAddentitlementOnModelAsset`,
  addSoftwareAssignedRequest: `${Base_URI}/softwareAssest/addSoftwareAssignedRequest`,
  getSoftwareAssignedRequestByUser : `${Base_URI}/softwareAssest/getSoftwareAssignedRequestByUser`,
  addSoftwareRemovedAsset : `${Base_URI}/softwareAssest/addSoftwareRemovedAsset`,
  getSoftwareRemovedAsset : `${Base_URI}/softwareAssest/getSoftwareRemovedAsset`,
  getSoftwareAddentitlementBySoftwareModalId : `${Base_URI}/softwareAssest/getSoftwareAddentitlementBySoftwareModalId`,
  addSoftwareRequestByUser : `${Base_URI}/softwareAssest/addSoftwareRequestByUser`,
  getSoftwareRequestByUser : `${Base_URI}/softwareAssest/getSoftwareRequestByUser`,
  getSoftwarePendingRequestByUser : `${Base_URI}/softwareAssest/getSoftwarePendingRequestByUser`,
  updateSoftwarePendingRejectAssign : `${Base_URI}/softwareAssest/updateSoftwarePendingRejectAssign`,
  getSoftwareAssignedForUser : `${Base_URI}/softwareAssest/getSoftwareAssignedForUser`,
  getSoftwareAssignedAndPendingForUser : `${Base_URI}/softwareAssest/getSoftwareAssignedAndPendingForUser`,
  deleteSoftwareModelAsset : `${Base_URI}/softwareAssest/deleteSoftwareModelAsset`,
  deleteSoftwareAddentitlementById : `${Base_URI}/softwareAssest/deleteSoftwareAddentitlementById`,
  getDepartmentName : `${Base_URI}/softwareAssest/getDepartmentName`,
  getHODandEmployeeName : `${Base_URI}/softwareAssest/getHODandEmployeeName`,
  getAssetsDetails : `${Base_URI}/softwareAssest/getAssetsDetails`,
  addSoftwareModelAssetBulk : `${Base_URI}/softwareAssest/addSoftwareModelAssetBulk`,
  addSoftwareAddentitlementBulk : `${Base_URI}/softwareAssest/addSoftwareAddentitlementBulk`,
  sendOtpForLicenseKeyByEmail : `${Base_URI}/softwareAssest/sendOtpForLicenseKeyByEmail`,
  verifyOtpByEmailForLicenseKey : `${Base_URI}/softwareAssest/verifyOtpByEmailForLicenseKey`,
  getVerifiedLicenseKey : `${Base_URI}/softwareAssest/getVerifiedLicenseKey`,
  simpleExlShiftReport:`${Base_URI}/attendance_management/simpleExlShiftReport`,
  ShiftUploadMonthwise:`${Base_URI}/attendance_management/upload-shift-month-wise`,
  updateWeekoff:`${Base_URI}/attendance_management/updateWeekoff`,
     //  ------------announcement--------------
  getAllAnnouncement:  `${Base_URI}/rest_api/get-all-announcements`,
  deleteAnnouncemnet:`${Base_URI}/rest_api/delete-announcements`,
  createAnnouncement: `${Base_URI}/rest_api/create-announcements`,
  
    //  ------------Kudos--------------
    addKudosCategory :`${Base_URI}/kudos/add-kudos-category`,
    getKudosCategory :`${Base_URI}/kudos/get-kudos-category`,
    createKudosRequest:`${Base_URI}/kudos/create-kudos-request`,
    approveKudosRequest:`${Base_URI}/kudos/approve-kudos-request`,
    getKudosRequest :`${Base_URI}/kudos/get-kudos-request`,
    getKudosType :`${Base_URI}/kudos/get-kudos-types`,
    getKudosCategoryTypes :`${Base_URI}/kudos/get-kudos-category-types`,
    getAllManagers :`${Base_URI}/kudos/get-all-managers`,
    getAllEmployee :`${Base_URI}/kudos/get-employee`,
    getCreatedKudosRequest :`${Base_URI}/kudos/get-created-kudos-request`,
    getPointStatement :`${Base_URI}/kudos/get-point-statement`,
    getPointSummary :`${Base_URI}/kudos/get-point-summary`,
    topEarnersList :`${Base_URI}/kudos/top-earners-list`,
    getPointValue :`${Base_URI}/kudos/get-point-value`,
    redeemPoint :`${Base_URI}/kudos/redeem-point`,
    dateWiseManagerReport:`${Base_URI}/kudos/date-wise-manager-report`,
    getRedeemRequest: `${Base_URI}/kudos/get-redeem-request`,
    getUserRedeemRequest: `${Base_URI}/kudos/get-user-redeem-request`,
    approveRedeemPointRequest: `${Base_URI}/kudos/approve-redeem-point-request`,
    getUserKudos: `${Base_URI}/kudos/get-user-type-approve-kudos`,
    getUserAllKudos: `${Base_URI}/kudos/get-user-all-kudos`,
   ////////loan/////////////////
   employeeLoanDetail: `${Base_URI}/rest_api/get-all-loans`,
   addLoan: `${Base_URI}/rest_api/add-loans`,
   updateLoans: `${Base_URI}/rest_api/update-loans`,
   
  // ------appraisal-----
  addAppraisal: `${Base_URI}/admin/add-appraisal`,
  getDesignationForDepartments: `${Base_URI}/admin/get-designation-for-multiple-department`,
  updateAppraisal: `${Base_URI}/admin/update-appraisal`,
  getDesignationForAppraisal: `${Base_URI}/admin/designation-for-appraisal`,
  getEmployeeForDesignation: `${Base_URI}/admin/get-employee-for-multiple-designation`,
  addSalaryBracket: `${Base_URI}/admin/add-salary-bracket`,
  updateSalaryBracket: `${Base_URI}/admin/update-salary-brackets`,
  updateDropdownRules: `${Base_URI}/admin/update-dropdown-rules`,
  getAppraisalList: `${Base_URI}/admin/appraisal-list`,
  employeeForAppraisal: `${Base_URI}/admin/employee-data-for-appraisal`,
  addAppraisalExceptions: `${Base_URI}/admin/add-appraisal-exceptions`,
  approveRejectAppraisal: `${Base_URI}/admin/approve-reject-appraisal`,
  sendAppraisalLetter: `${Base_URI}/admin/send-appraisal-letter`,
  getSalaryBracket: `${Base_URI}/admin/salary-bracket-list`,
  generateReportForLeaveCorrection: `${Base_URI}/attendance_management/generate-report-leave-correction`,

  //add investment settings 
  addPayrollInvestmentSettings: `${Base_URI}/rest_api/add-investment-settings`,
  getInvestmentSettings: `${Base_URI}/rest_api/get-investment-settings`,

  // manage attendance
  manageManagerAttendance: `${Base_URI}/admin/manage-attendance`,
  

  //commercial attributs
  addCommercialAttributes: `${Base_URI}/rest_api/add-commercial-attributes`,
  getCommercialAttributesLookup: `${Base_URI}/rest_api/get-commercial-attributes-lookup`,
  getCommercialAttributes: `${Base_URI}/rest_api/get-commercial-attributes`,
  getAllSkills:`${Base_URI}/rest_api/all-skills`,
  addCommercials: `${Base_URI}/rest_api/add-commercials`,
  allCommercials: `${Base_URI}/rest_api/all-commercials`,
  
  hikvisionAttendancePopulation: `${Base_URI}/admin/hikvisionAttendancePopulation`,
  getAllHOD: `${Base_URI}/attendance_management/get-all-HOD`,
  getNotification: `${Base_URI}/common_api/getNotification`,
  notificationSeen: `${Base_URI}/common_api/notificationSeen`,
  clearAllNotification: `${Base_URI}/common_api/clearAllNotification`,
  clearOneNotification: `${Base_URI}/common_api/clearOneNotification`,
  createReportAbsconding: `${Base_URI}/common_api/createReportAbsconding`,
  findAllStatus: `${Base_URI}/common_api/find-all-status`,
  updateAttendanceByHR: `${Base_URI}/common_api/update-attendance-manual`,

  // webcast api
   getAllWebcast: `${Base_URI}/community/get-all-webcast`,
   getAllQuestionWebcast: `${Base_URI}/community/get-all-question-webcast`,
   createWebcast: `${Base_URI}/community/create-webcast`,
   updateWebcast: `${Base_URI}/community/update-webcast`,
   deleteWebcast: `${Base_URI}/community/delete-webcast`,
   addQuestionForWebcast: `${Base_URI}/community/add-question-for-webcast`,
 //team api
  getTeamList : `${Base_URI}/common_api/get-all-team`,
  deleteWebcastQuestion: `${Base_URI}/community/delete-webcast-question`,

  // comunity api
  getAllPost:`${Base_URI}/community/get-all-post`,
  createPost:`${Base_URI}/community/create-post`,
  commentPost:`${Base_URI}/community/comment-post`,
  getCommentsById:`${Base_URI}/community/get-comments-by-id`,
  sharePost:`${Base_URI}/community/share-post`,
  likePost:`${Base_URI}/community/like-post`,
  deletePost:`${Base_URI}/community/delete-post`,
  updatePost:`${Base_URI}/community/update-post`,
  getLikesById:`${Base_URI}/community/get-likes-by-id`,
  deleteComment:`${Base_URI}/community/delete-comment`,
  updateComment:`${Base_URI}/community/update-comment`,

  // employee history
  getEmploymentHistory:`${Base_URI}/admin/get-employment-history`,
  getEmploymentHistoryConstant:`${Base_URI}/admin/get-employment-history-constant`,
  saveSalaryImpacted: `${Base_URI}/admin/save-salary-impacted`,

  // warning tamplete Api
  updateWarningTemplate:`${Base_URI}/pms/update-warning-template`,
  getGivenWarnings:`${Base_URI}/pms/get-given-warnings`,
  saveWarningTemplate:`${Base_URI}/pms/save-warning-template`,

  giveSelfRatingForCurrentMonth:`${Base_URI}/pms/give-self-rating-for-current-month`,
  userSelfRatingPermissions:`${Base_URI}/pms/user-self-rating-permissions`,
  pmsSelfRatingYesNoReport: `${Base_URI}/pms/pms-self-rating-yes-no-report`,

  //attrition-api
  attritionDashboard:`${Base_URI}/common_api/attrition-dashboard`,
};
